import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

import { userSelector } from './utils/store/userSlice';
import AdminHeader from './utils/components/header';
import getEnvironement from './environnement';
import searchIcon from './utils/assets/icons/search-normal.png';
import PackCategoryTable from './utils/components/tables/packCategoryTable';
import AddCategoryDrawer from './utils/components/drawers/addCategoryDrawer';
import UpdateCategoryDrawer from './utils/components/drawers/updateCategoryDrawer';
import DeleteCategoryConfirmDialog from './utils/components/dialog/deleteCategoryConfirmDialog';

export default function AdminPacksCategories() {
    const user = useSelector(userSelector);

    const [allCategories, setAllCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isEmptyCategories, setIsEmptyCategories] = useState(false);

    const [openAddCategoryDrawer, setOpenAddCategoryDrawer] = useState(false);
    const [addCategoryErrors, setAddCategoryErrors] = useState(null);

    const [openUpdateCategoryDrawer, setOpenUpdateCategoryDrawer] = useState(false);
    const [updateCategoryErrors, setUpdateCategoryErrors] = useState(null);
    const [updatedCategory, setUpdatedCategory] = useState([]);

    const [openDeleteCategoryConfirmDialog, setOpenDeleteCategoryConfirmDialog] = useState(false);
    const [deletedCategoryId, setDeletedCategoryId] = useState();

    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);

    const getCategories = async () => {
        setCircularProgress(true);
        const url = `${getEnvironement().API_URL}/admin/categories`;
        const params = { managerId: user.id };
        await axios.get(url, { params })
            .then((response) => {
                setAllCategories(response.data);
                setCategories(response.data);
                if (response.data.length === 0) {
                    setIsEmptyCategories(true);
                } else {
                    setIsEmptyCategories(false);
                }
            })
            .finally(() => setCircularProgress(false))
            .catch((err) => {
                console.log(err);
            });
    };

    const getSelectedCategory = async (id) => {
        setOpenBackDrop(true);
        const url = `${getEnvironement().API_URL}/admin/categories/${id}`;
        await axios.get(url)
            .then((response) => {
                setUpdatedCategory(response.data);
            })
            .finally(() => setOpenBackDrop(false))
            .then(() => setOpenUpdateCategoryDrawer(true))
            .catch((err) => {
                console.log(err);
            });
    };

    const addCategory = async (category) => {
        const url = `${getEnvironement().API_URL}/admin/categories`;
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.post(url, { managerId: user.id, ...category }, config)
            .then(() => setAddCategoryErrors(null))
            .then(() => setOpenAddCategoryDrawer(false))
            .then(() => getCategories())
            .then(() => {
                toast.success('La catégorie a bien été ajoutée', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
            })
            .catch((err) => {
                setAddCategoryErrors(err.response.data.errors);
            });
    };

    const updateCategory = async (selectedCategory) => {
        const url = `${getEnvironement().API_URL}/admin/categories/${selectedCategory.id}`;
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.put(url, selectedCategory, config)
            .then(() => setUpdateCategoryErrors(null))
            .then(() => setOpenUpdateCategoryDrawer(false))
            .then(() => getCategories())
            .then(() => {
                toast.success('La catégorie a bien été modifiée', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
            })
            .catch((err) => {
                setUpdateCategoryErrors(err.response.data.errors);
            });
    };

    const deleteCategory = async (idCategory) => {
        setCircularProgress(true);
        const url = `${getEnvironement().API_URL}/admin/categories/${idCategory}`;
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        await axios.delete(url, config)
            .then(() => getCategories())
            .finally(() => setCircularProgress(false))
            .then(() => {
                toast.success('La catégorie a bien été supprimée', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
            })
            .catch(() => {
                toast.error('Erreur de suppression de la catégorie', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
                getCategories();
                setCircularProgress(false);
            });
    };

    const handleChangeSearchInput = (e) => {
        setCategories(allCategories.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <div className='py-4'>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <AddCategoryDrawer
                open={openAddCategoryDrawer}
                onClose={() => { setOpenAddCategoryDrawer(false); setAddCategoryErrors(null); }}
                onCategoryAdded={addCategory}
                errors={addCategoryErrors}
            />
            <UpdateCategoryDrawer
                open={openUpdateCategoryDrawer}
                onClose={() => { setOpenUpdateCategoryDrawer(false); setUpdateCategoryErrors(null); }}
                onCategoryUpdated={updateCategory}
                selectedCategory={updatedCategory}
                errors={updateCategoryErrors}
            />
            <DeleteCategoryConfirmDialog
                open={openDeleteCategoryConfirmDialog}
                onClose={() => setOpenDeleteCategoryConfirmDialog(false)}
                onClickDeleteCategory={() => {
                    deleteCategory(deletedCategoryId);
                    setOpenDeleteCategoryConfirmDialog(false);
                }}
            />
            <AdminHeader name={user.nom} />
            <div className='flex justify-between items-center mb-5'>
                <p className='font-poppins font-medium text-2xl'>Toutes les catégories de packs</p>
                <Button
                    variant='contained'
                    onClick={() => setOpenAddCategoryDrawer(true)}
                >
                    + Ajouter une nouvelle catégorie
                </Button>
            </div>
            <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
                <img src={searchIcon} />
                <input
                    type='search'
                    placeholder='Chercher par catégorie'
                    onChange={(e) => handleChangeSearchInput(e)}
                    className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
                />
            </div>
            <PackCategoryTable
                header={['Id', 'Nom', 'Titre', 'Date', 'Actions']}
                data={categories}
                onClickUpdateCategory={(idCategory) => getSelectedCategory(idCategory)}
                onClickDeleteCategory={(idCategory) => {
                    setOpenDeleteCategoryConfirmDialog(true);
                    setDeletedCategoryId(idCategory);
                }}
            />
            {isEmptyCategories && (
                <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw'>
                    Aucune catégorie
                </div>
            )}
            {circularProgress && (
                <div className='flex justify-center p-5'>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}
