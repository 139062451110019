import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Chip,
  Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/HourglassEmpty';

const formatPrice = (value) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'MAD',
  })
    .format(value)
    .replace('MAD', 'DH');
};
const PaymentsTable = ({ payments, validatePayment }) => {
  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Référence</TableCell>
            <TableCell>Utilisateur</TableCell>
            <TableCell>Montant</TableCell>
            <TableCell>Référence de Transaction </TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <TableRow key={payment.id}>
              <TableCell>{payment.reference}</TableCell>
              <TableCell>{payment.userName}</TableCell>
              <TableCell>{formatPrice(payment.amount)}</TableCell>
              <TableCell>
                {/* Displaying payment method with Chip and Typography */}
                {payment.paymentMethod === 'stripe' && (
                  <Chip label={`Référence Stripe: ${payment.referenceStripe}`} color="primary" variant="outlined" />
                )}
                {payment.paymentMethod === 'virement' && (
                  <Chip label={`Référence Virement: ${payment.referenceVirement}`} color="secondary" variant="outlined" />
                )}
                {payment.paymentMethod === 'cheque' && (
                  <Chip label={`Numéro Chèque: ${payment.numeroCheque}`} color="default" variant="outlined" />
                )}
                {payment.paymentMethod === 'paypal' && (
                  <Chip label={`ID Transaction PayPal: ${payment.idTransactionPaypal}`} color="info" variant="outlined" />
                )}
              </TableCell>
              <TableCell>{payment.date}</TableCell>
              <TableCell>
                <Tooltip title={payment.status === 'Validated' ? 'Payment has been validated' : 'Payment is pending'}>
                  <span>
                    {payment.status === 'Validated' ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <PendingIcon color="warning" />
                    )}
                  </span>
                </Tooltip>
              </TableCell>

              <TableCell>
                <Tooltip title={payment.status === 'Validated' ? 'Payment already validated' : 'Validate payment'}>
                  <span>
                    <IconButton
                      color="primary"
                      disabled={payment.status === 'Validated'}
                      onClick={() => validatePayment(payment.id)}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {payments.length === 0 && (
        <div style={{ textAlign: 'center', padding: '20px' }}>Aucun paiement trouvé.</div>
      )}
    </TableContainer>
  );
};

export default PaymentsTable;
