const FR_LANG = {
  login: {
    connexion: 'Connexion:',
    input: {
      email: 'Connexion:',
      password: 'Mot de passe:',
    },
    stayConnected: 'Rester connecté',
    connect: 'Se connecter'
  },
  sideBar: {
    dashboard: 'Dashboard',
    clients: 'Clients',
    managers: 'Géstionnaires',
    packs: 'Packs',
    packCategories: 'Catégories de packs',
    status: 'Status',
    profile: 'Profile',
    confirmLogout: 'Êtes-vous sûr de vouloir se déconnecter ?',
    domiciliationCenters: 'Centres de domiciliation',
    payments: 'Paiements',
    settings: 'Paramètres',
    logout: 'Déconnexion',
  },
  clients: {
    addClientsDialog: {
      addNewClient: 'Ajouter un nouveau client :',
      lastName: 'Nom',
      firstName: 'Prénom',
      email: 'Email',
      phone: 'Téléphone',
      birthDate: 'Date de naissance',
      password: 'Mot de passe',
      confirmPassword: 'Confirmation de mot de passe',
      cancel: 'annuler',
      add: 'ajouter'
    },
    managers: {
      addClientsDialog: {
        addNewClient: 'Ajouter un nouveau client :',
        lastName: 'Nom',
        firstName: 'Prénom',
        email: 'Email',
        phone: 'Téléphone',
        birthDate: 'Date de naissance',
        password: 'Mot de passe',
        confirmPassword: 'Confirmation de mot de passe',
        cancel: 'annuler',
        add: 'ajouter'
      },
    },
    updateClientsDialog: {
      updateClient: 'Modifier un client :',
      lastName: 'Nom',
      firstName: 'Prénom',
      email: 'Email',
      phone: 'Téléphone',
      birthDate: 'Date de naissance',
      password: 'Mot de passe',
      confirmPassword: 'Confirmation de mot de passe',
      cancel: 'annuler',
      update: 'modifier'
    },
    deleteClientConfirmDialog: {
      deleteClient: 'Supprimer un client',
      deleteConfirmationContent: 'voulez vous vraiment supprimer cet utilisateur ?',
      cancel: 'Annuler',
      delete: 'Supprimer',
    },
    clientTable: {
      consult: 'Consulter',
      update: 'Modifier',
      delete: 'Supprimer',
    },
    addClientSuccess: 'client a bien été ajouté',
    updateClientSuccess: 'client a bien été modifié',
    deleteClientSuccess: 'client a bien été supprimé',
    deleteClientError: 'Merci de supprimer tous les produits liés à ce client avant de le supprimer',
    allClients: 'Tous les clients',
    addNewClient: '+ Ajouter un nouveau client',
    searchByName: 'Chercher par Nom',
    emptyClient: 'Aucun client',
    tableHeader: {
      idClient: 'id_client',
      lastName: 'nom',
      firstName: 'prenom',
      email: 'email',
      phone: 'téléphone',
      date: 'Date',
      status: 'Status',
      actions: 'Actions'
    }
  },
  domiciliationCenters: {
   
    addUpdateDomiciliationCenterDrawer: {
      updateCenter: 'Modifier un centre de domiciliation :',
      addNewCenter: 'Ajouter un nouveau centre de domiciliation :',
      name: 'Nom',
      address: 'Adresse',
      phone: 'Téléphone',
      email: 'Email',
      city: 'Ville',
      fax: 'Fax',
      rc: 'RC',
      patente: 'Patente',
      if: 'IF',
      cnss: 'CNSS',
      ice: 'ICE',
      manager: 'Nom du responsable',
      cin_manager: 'Cin du responsable',
      address_manager: 'Address du responsable',
      date_mirth_manager: 'Date de naissance du responsable',
      cancel: 'Annuler',
      update: 'Modifier',
      add: 'ajouter',
      uploadLogo: 'Télécharger le logo',
      chooseLogo: 'choisissez le logo',
    },

    deleteDomiciliationCenterConfirmDialog: {
      deleteDomiciliationCenter: 'Supprimer un centre de domiciliation',
      deleteConfirmationContent: 'Voulez-vous vraiment supprimer ce centre de domiciliation ?',
      cancel: 'Annuler',
      delete: 'Supprimer',
    },
    centerTable: {
      consult: 'Consulter',
      update: 'Modifier',
      delete: 'Supprimer',
    },
    allDomiciliationCenters: 'Tous les centers de domiciliation',
    addCenterSuccess: 'Centre de domiciliation a bien été ajouté',
    updateDomiciliationCenterSuccess: 'Centre de domiciliation a bien été modifié',
    deleteDomiciliationCenterSuccess: 'Centre de domiciliation a bien été supprimé',
    deleteCenterError: 'Merci de supprimer toutes les associations liées à ce centre avant de le supprimer',
    allCenters: 'Tous les centres de domiciliation',
    addNewCenter: '+ Ajouter un nouveau centre de domiciliation',
    searchByName: 'Chercher par Nom',
    emptyDomiciliationCenters: 'Aucun centre de domiciliation',
    tableHeader: {
      idCenter: 'ID Centre',
      name: 'Nom',
      address: 'Adresse',
      phone: 'Téléphone',
      email: 'Email',
      date: 'Date',
      actions: 'Actions',
    },
  },
};

export default FR_LANG;