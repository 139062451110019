import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, createTheme, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import getEnvironement, { getLang } from '../../../environnement';
import UploadIcon from '@mui/icons-material/Upload';
import app_logo from '../../assets/images/LOGO-LS@2x.png';

export default function UpdateDomiciliationCenterDrawer({
  onClose,
  open,
  onClickUpdateDomiciliationCenter,
  center = {},
  errors,
}) {
  const [lang] = useState('FR');
  const [name, setName] = useState(center.name);
  const [address, setAddress] = useState(center.address);
  const [city, setCity] = useState(center.city);
  const [phone, setPhone] = useState(center.phone);
  const [fax, setFax] = useState(center.fax);
  const [email, setEmail] = useState(center.email);
  const [rc, setRc] = useState(center.rc);
  const [patente, setPatente] = useState(center.patente);
  const [ifCode, setIfCode] = useState(center.ifCode);
  const [cnss, setCnss] = useState(center.cnss);
  const [ice, setIce] = useState(center.ice);
  const [manager, setManager] = useState(center.manager);
  const [cin_manager, setCin_manager] = useState(center.cin_manager);
  const [address_manager, setAddress_manager] = useState(center.address_manager);
  const [date_birth_manager, setDate_birth_manager] = useState(center.date_birth_manager);
  const [localErrors, setLocalErrors] = useState({});
  const [logo, setLogo] = useState(center.logo || ''); // State for storing the logo
  const [selectedLogo, setSelectedLogo] = useState(null);

  useEffect(() => {
    setName(center.name);
    setAddress(center.address);
    setCity(center.city);
    setPhone(center.phone);
    setFax(center.fax);
    setEmail(center.email);
    setRc(center.rc);
    setPatente(center.patente);
    setIfCode(center.ifCode);
    setCnss(center.cnss);
    setIce(center.ice);
    setManager(center.manager);
    setCin_manager(center.cin_manager);
    setAddress_manager(center.address_manager);
    setDate_birth_manager(center.date_birth_manager);
    setLogo(center.logo || '');

    setLocalErrors({});
  }, [center]);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {

      setLogo(file);

      const previewUrl = URL.createObjectURL(file);
      setSelectedLogo(previewUrl);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Le nom est requis';
    if (!address) newErrors.address = 'L\'adresse est requise';
    if (!city) newErrors.city = 'La ville est requise';
    if (email && !/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(email)) newErrors.email = 'Format de l\'email invalide';
    if (phone && !/^[\d\s]+$/.test(phone)) newErrors.phone = 'Le téléphone doit contenir uniquement des chiffres';
    if (fax && !/^[\d\s]+$/.test(fax)) newErrors.fax = 'Le fax doit contenir uniquement des chiffres';
    if (rc && !/^\d+$/.test(rc)) newErrors.rc = 'Le RC doit contenir uniquement des chiffres';
    if (!patente) newErrors.patente = 'La patente est requise';
    if (ifCode && !/^\d+$/.test(ifCode)) newErrors.ifCode = 'Le code IF doit contenir uniquement des chiffres';
    if (cnss && !/^\d+$/.test(cnss)) newErrors.cnss = 'Le CNSS doit contenir uniquement des chiffres';
    if (ice && !/^\d+$/.test(ice)) newErrors.ice = 'L\'ICE doit contenir uniquement des chiffres';
    if (!manager) newErrors.manager = 'Le nom du manager est requis';
    if (!cin_manager) newErrors.cin_manager = 'Le CIN est requis';
    if (!address_manager) newErrors.address_manager = 'L\'adresse du manager est requise';
    if (!date_birth_manager) newErrors.date_birth_manager = 'La date de naissance du manager est requise';
    // Validation du logo : vérifier si un logo est sélectionné et s'il s'agit d'un fichier image
    if (!logo ) {
      newErrors.logo = 'Le logo est requis';
    }  else if (logo.size > 5 * 1024 * 1024) { // Vérifier si le fichier dépasse 5 Mo
      newErrors.logo = 'Le logo doit être inférieur à 5 Mo';
    }

    setLocalErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleUpdate = () => {
    if (validateFields()) {
      onClickUpdateDomiciliationCenter({
        id: center.id,
        name,
        address,
        city,
        phone,
        fax,
        email,
        rc,
        patente,
        ifCode,
        cnss,
        ice,
        manager,
        cin_manager,
        address_manager,
        date_birth_manager,
        logo,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Drawer anchor="left" open={open} onClose={onClose}>
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
            <div className='flex w-full justify-between items-center'>
              <img src={app_logo} alt='logo' className='w-[300px]' />
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </div>
          </div>
          <div style={{ padding: '20px', width: '50vw' }}>

            <h2 className='font-poppins font-medium text-2xl mb-5'>
              {getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.updateCenter}
            </h2>
            <Grid container spacing={2}>
              {/* Name */}
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.name}
                  variant="outlined"
                  margin="dense"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!!(errors?.name || localErrors.name)}
                  helperText={errors?.name || localErrors.name || ''}
                  fullWidth
                />
              </Grid>
              {/* Address */}
              <Grid item xs={12}>
                <TextField
                  id="address"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.address}
                  variant="outlined"
                  margin="dense"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  error={!!(errors?.address || localErrors.address)}
                  helperText={errors?.address || localErrors.address || ''}
                  fullWidth
                />
              </Grid>
              {/* City */}
              <Grid item xs={12}>
                <TextField
                  id="city"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.city}
                  variant="outlined"
                  margin="dense"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  error={!!(errors?.city || localErrors.city)}
                  helperText={errors?.city || localErrors.city || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* Email */}
              <Grid item xs={6}>
                <TextField
                  id="email"
                  type="email"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.email}
                  variant="outlined"
                  margin="dense"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!(errors?.email || localErrors.email)}
                  helperText={errors?.email || localErrors.email || ''}
                  fullWidth
                />
              </Grid>
              {/* Phone */}
              <Grid item xs={6}>
                <TextField
                  id="phone"
                  type="tel"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.phone}
                  variant="outlined"
                  margin="dense"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={!!(errors?.phone || localErrors.phone)}
                  helperText={errors?.phone || localErrors.phone || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* Fax */}
              <Grid item xs={6}>
                <TextField
                  id="fax"
                  type="tel"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.fax}
                  variant="outlined"
                  margin="dense"
                  value={fax}
                  onChange={(e) => setFax(e.target.value)}
                  error={!!(errors?.fax || localErrors.fax)}
                  helperText={errors?.fax || localErrors.fax || ''}
                  fullWidth
                />
              </Grid>
              {/* RC */}
              <Grid item xs={6}>
                <TextField
                  id="rc"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.rc}
                  variant="outlined"
                  margin="dense"
                  value={rc}
                  onChange={(e) => setRc(e.target.value)}
                  error={!!(errors?.rc || localErrors.rc)}
                  helperText={errors?.rc || localErrors.rc || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* Patente */}
              <Grid item xs={6}>
                <TextField
                  id="patente"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.patente}
                  variant="outlined"
                  margin="dense"
                  value={patente}
                  onChange={(e) => setPatente(e.target.value)}
                  error={!!(errors?.patente || localErrors.patente)}
                  helperText={errors?.patente || localErrors.patente || ''}
                  fullWidth
                />
              </Grid>
              {/* IF */}
              <Grid item xs={6}>
                <TextField
                  id="ifCode"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.if}
                  variant="outlined"
                  margin="dense"
                  value={ifCode}
                  onChange={(e) => setIfCode(e.target.value)}
                  error={!!(errors?.ifCode || localErrors.ifCode)}
                  helperText={errors?.ifCode || localErrors.ifCode || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* CNSS */}
              <Grid item xs={6}>
                <TextField
                  id="cnss"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.cnss}
                  variant="outlined"
                  margin="dense"
                  value={cnss}
                  onChange={(e) => setCnss(e.target.value)}
                  error={!!(errors?.cnss || localErrors.cnss)}
                  helperText={errors?.cnss || localErrors.cnss || ''}
                  fullWidth
                />
              </Grid>
              {/* ICE */}
              <Grid item xs={6}>
                <TextField
                  id="ice"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.ice}
                  variant="outlined"
                  margin="dense"
                  value={ice}
                  onChange={(e) => setIce(e.target.value)}
                  error={!!(errors?.ice || localErrors.ice)}
                  helperText={errors?.ice || localErrors.ice || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* Manager */}
              <Grid item xs={12}>
                <TextField
                  id="manager"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.manager}
                  variant="outlined"
                  margin="dense"
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                  error={!!(errors?.manager || localErrors.manager)}
                  helperText={errors?.manager || localErrors.manager || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* Manager CIN */}
              <Grid item xs={12}>
                <TextField
                  id="cin_manager"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.cin_manager}
                  variant="outlined"
                  margin="dense"
                  value={cin_manager}
                  onChange={(e) => setCin_manager(e.target.value)}
                  error={!!(errors?.cin_manager || localErrors.cin_manager)}
                  helperText={errors?.cin_manager || localErrors.cin_manager || ''}
                  fullWidth
                />
              </Grid>
              {/* Manager Address */}
              <Grid item xs={12}>
                <TextField
                  id="address_manager"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.address_manager}
                  variant="outlined"
                  margin="dense"
                  value={address_manager}
                  onChange={(e) => setAddress_manager(e.target.value)}
                  error={!!(errors?.address_manager || localErrors.address_manager)}
                  helperText={errors?.address_manager || localErrors.address_manager || ''}
                  fullWidth
                />
              </Grid>
              {/* Manager Date of Birth */}
              <Grid item xs={12}>
                <TextField
                  id="date_birth_manager"
                  type="date"
                  label={getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.date_birth_manager}
                  variant="outlined"
                  margin="dense"
                  value={date_birth_manager}
                  onChange={(e) => setDate_birth_manager(e.target.value)}
                  error={!!(errors?.date_birth_manager || localErrors.date_birth_manager)}
                  helperText={errors?.date_birth_manager || localErrors.date_birth_manager || ''}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            {/* Logo Upload */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  {getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.uploadLogo}
                </Typography>
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleLogoChange}  // Handle logo change without binding the value
                  style={{ display: 'none' }}
                />
                <label htmlFor="logo">
                  <Button variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<UploadIcon />}
                    fullWidth>
                    {getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.chooseLogo}

                  </Button>
                </label>

                {logo && <img src={
                  selectedLogo
                    ? selectedLogo
                    : `${getEnvironement().BACKEND_URL}/storage/app/public/${logo}`} alt="Logo Preview" style={{ width: '100%', marginTop: '10px', maxHeight: '200px', objectFit: 'contain' }} />}
                {localErrors?.logo && (
                  <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
                    {localErrors?.logo}
                  </Typography>
                )}

              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
              <Grid item xs={6}>
                <Button variant="contained" size="large" onClick={onClose} fullWidth>
                  {getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.cancel}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleUpdate}
                  fullWidth
                >
                  {getLang(lang).domiciliationCenters.addUpdateDomiciliationCenterDrawer.update}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {

    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        },
      },
    },
  },
});
