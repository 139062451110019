import React from 'react';
import AdminDashboard from '../../dashboard';
import AdminClients from '../../clients';
import DomiciliationCenters from '../../domiciliation-centers';
import AdminProfile from '../../profile';
import AdminPacks from '../../packs';
import AdminPacksCategories from '../../packs-categories';
import AdminStatus from '../../status';
import AdminManagers from '../../managers';
import AdminPayment from '../../payments';
import DetailsClient from '../../detailsClient';

function AdminContent({ activeTab, tabData }) {
  return (
    <div id='mainbody' className="flex-1 px-10 max-h-screen overflow-y-auto">
      {activeTab === 'dashboard' && <AdminDashboard />}
      {activeTab === 'clients' && <AdminClients />}
      {activeTab === 'managers' && <AdminManagers />}
      {activeTab === 'packs' && <AdminPacks />}
      {activeTab === 'pack-categories' && <AdminPacksCategories />}
      {activeTab === 'status' && <AdminStatus />}
      {activeTab === 'profile' && <AdminProfile />}
      {activeTab === 'domiciliation-centers' && <DomiciliationCenters />}
      {activeTab === 'payments' && <AdminPayment />}
      {activeTab === 'details-client' && <DetailsClient client={tabData} />}
    </div>
  );
}

export default AdminContent;
