import React, { useEffect, useState } from 'react';
import { IconButton, Button, createTheme, Grid, TextField, ThemeProvider, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import app_logo from '../../assets/images/LOGO-LS@2x.png';
export default function UpdateStatusDrawer({
  onClose,
  open,
  selectedStatus,
  onClickUpdateStatus,
  errors,
}) {
  const [status, setStatus] = useState('');

  useEffect(() => {
    setStatus(selectedStatus.status);
  }, [selectedStatus]);

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': { width: '70wv', maxWidth: '90wv' },
        }}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
            <div className='flex w-full justify-between items-center'>
              <img src={app_logo} alt='logo' className='w-[300px]' />
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </div>
          </div>
          <div style={{ padding: '100px  20px 20px 20px', width: '50vw' }}>

            <h2 className='font-poppins font-medium text-2xl mb-5'>Modifier un status:</h2>

            <Grid container spacing={2}>


              <Grid item xs={12}>
                <TextField
                  id="status"
                  label="Status:"
                  variant="outlined"
                  margin="dense"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  error={errors && errors.status}
                  helperText={errors && errors.status ? errors.status : ''}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={onClose}
                    fullWidth
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => onClickUpdateStatus({
                      id: selectedStatus.id,
                      status
                    })}
                    fullWidth
                  >
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {

    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        },
      },
    },
  },
});
