
import moment from "moment-hijri";
/**
 * Formats a number as Moroccan Dirham (DH).
 * @param {number} amount - The amount to format.
 * @returns {string} - The formatted amount with "DH".
 */
const FormatMoneyDH = (amount) => {
    if (isNaN(amount) || amount === null) return "0 DH"; // Handle invalid or null amounts
    return `${Number(amount).toLocaleString('en-MA', { minimumFractionDigits: 2 })} DH`;
};
/**
  * Calculate the montant (amount) based on capital and percentage.
  * @param {number} capital - The base capital amount.
  * @param {number} percentage - The percentage to calculate (as a value, e.g., 10 for 10%).
  * @returns {number} - The calculated montant (amount).
  */
function calculateMontant(capital, percentage) {
    // Convert input values to numbers
    const capitalNumber = Number(capital);
    const percentageNumber = Number(percentage);

    // Validate input types
    if (isNaN(capitalNumber) || isNaN(percentageNumber)) {
        throw new Error("Both capital and percentage must be numbers.");
    }

    // Validate input values
    if (capitalNumber < 0 || percentageNumber < 0) {
        throw new Error("Capital and percentage must be non-negative.");
    }

    return (capitalNumber * percentageNumber) / 100;
}


const formatDateToFrench = (date, includeYear = true) => {
    if (!date) return null;

    const months = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre',
    ];

    const parsedDate = new Date(date);
    const day = parsedDate.getDate() === 1 ? '1er' : parsedDate.getDate();
    const month = months[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();

    return includeYear ? `${day} ${month} ${year}` : `${day} ${month}`;
};





const GetHijriDateNow = () => {
    // Arabic to English month name mapping
    const hijriMonthMap = {
      "محرم": "Muharram",
      "صفر": "Safar",
      "ربيع الأول": "Rabi' al-Awwal",
      "ربيع الآخر": "Rabi' al-Thani",
      "جمادى الأول": "Jumada al-Awwal",
      "جمادى الآخر": "Jumada al-Thani",
      "رجب": "Rajab",
      "شعبان": "Sha'ban",
      "رمضان": "Ramadan",
      "شوال": "Shawwal",
      "ذو القعدة": "Dhu al-Qi'dah",
      "ذو الحجة": "Dhu al-Hijjah"
    };
  
    // Get the Hijri date in Arabic
    const hijriDate = moment().format("iD iMMMM iYYYY");
  
    // Extract the Arabic month name and map it to English
    const arabicMonth = hijriDate.split(' ')[1];
    const englishMonth = hijriMonthMap[arabicMonth];
  
    // Replace the Arabic month with the English one
    const formattedDate = hijriDate.replace(arabicMonth, englishMonth);
  
    return formattedDate; // Example: "21 Rajab 1446"
  };
  
  


export { formatDateToFrench, calculateMontant, FormatMoneyDH , GetHijriDateNow };