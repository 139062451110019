import React from 'react';

import { GetHijriDateNow } from '../../helpers';

const GenerateImmatriculationRcPDF = ({ productData, gerentsData, objetsSocialData }) => {
  return (
    <div className="pdf-container" style={{ width: '100%', fontFamily: 'Arial, sans-serif', lineHeight: 1.5, padding: '10mm' }}>
      <div className='pdf-content' >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 style={{ textAlign: 'justify', fontWeight: '900' }}>
            ROYAUME DU MAROC <br />
            MINISTERE DE LA JUSTICE <br /> --------</h2>

          <h2 style={{ textAlign: 'center', fontWeight: '900' }}> REGISTRE DU COMMERCE <br />
            DECLARATION D’IMMATRICULATION
          </h2>
          <p style={{ textAlign: 'center', fontWeight: '600' }}>Modèle n° 2  <br />
            Décret du {GetHijriDateNow()}
          </p>

        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: 'center', fontWeight: '900' }}>

          </div>
          <p >(Articles 45-46 du code de commerce) <span style={{ marginLeft: '150px' }}> (18/1/1997)   </span>      </p>

          <div> </div>
        </div>
        <br />

        <div className='pdf-content' style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontWeight: '700' }}> Tribunal de Commerce de Casablanca</p>
          <p style={{ fontWeight: '700', fontSize: '24px' }}>***</p>
          <p style={{ fontWeight: '600' }}>Article 2</p>
          <p></p>
          <p></p>
        </div>
        <div style={{ display: "flex", justifyContent: "right" }}>

          <p style={{ padding: '0px 10px 10px 10px', border: '1px solid black', textAlign: 'center', width: 'auto' }}>SOCIETES COMMERCIALES</p>

        </div>

        <div style={{ border: '1px solid black', padding: '10px', margin: '20px 0' }}>
          <h3 style={{ textAlign: 'center', fontSize: '25px', fontWeight: '900' }}>NOTA</h3>
          <p>
            La présente déclaration doit être rédigée en triple exemplaire, de façon très lisible, dactylographiée et signée par le requérant ou
            par son mandataire muni d’une procuration qui est conservée par le greffier.
          </p>
          <p>La déclaration doit être accompagnée des pièces justificatives exigées.</p>
          <p>
            Nul assujetti ou société commerciale ne peut être immatriculé à titre principal dans plusieurs registres locaux ou dans un même registre
            local sous plusieurs numéros (Art. 39 du code de commerce).
          </p>
          <p>
            Toute indication inexacte donnée de mauvaise foi ou en vue de l’immatriculation ou de l’inscription au registre du commerce est punie
            d’un emprisonnement d’un mois à un an et d’une amende de 1.000 à 50.000 dirhams ou de l’une de ces deux peines seulement (Art 64 du code de commerce).
          </p>
          <p>
            L’immatriculation d’une société ne peut être requise que par les gérants ou par les membres des organes d’administration, de direction ou de gestion
            (Article 38-2ème alinéa du code de commerce).
          </p>
        </div>
        <div style={{ paddingLeft: '10px' }}>
          <p>N° d’immatriculation: .................................................................   Raison sociale ou dénomination: <span style={{ fontWeight: '900', marginLeft: '20px' }}>{productData?.denominationValide} SARL</span>  </p>
        </div><br />
        <div style={{ marginBottom: '20px' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>Cadre réservé au greffier</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <p>Actes et pièces déposés le: _______________________ h: __________ sous n°: _______________________</p>
                  <p>Déclaration déposée le: _______________________ sous n°: _______________________ au registre chronologique.</p>
                  <p>
                    La conformité de la déclaration ci-dessus avec les pièces justificatives produites en application des règlements a été vérifiée par
                    le secrétaire-greffier soussigné qui a procédé en conséquence à l’immatriculation demandée, laquelle a reçu le numéro: _______________________
                  </p>
                  <p style={{ marginLeft: '250px', marginTop: '10px' }}>Le secrétaire-greffier</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div >
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>Cadre réservé au registre central</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px', height: '100px' }}>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <p style={{ textAlign: 'center', fontSize: '12px' }}>
            Modèle n° 2 <br />
            29,5 x 42 – 61g/
          </p>
        </div>
      </div>
      <div className="page-break"></div>

      <div className='pdf-content'>
        <div style={{ display: "flex", justifyContent: "space-between", width: '75%' }}>

          <h2 style={{ textAlign: 'justify', fontWeight: '600', marginLeft: '100px' }}>
            Déclaration d’immatriculation au registre du commerce</h2>

          <h2 style={{ textAlign: 'left', fontWeight: '600' }}>
            Modèle 2
          </h2>

        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: '75%' }}>

          <p style={{ marginLeft: '40px' }}>1) Raison sociale ou dénomination <span style={{ fontWeight: '900', marginLeft: '20px' }}>{productData?.denominationValide} </span> </p>
          <p> Enseigne ...................................... </p>

        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: '75%' }}>

          <p style={{ marginLeft: '40px' }} >Sigle …………………………………………………………………… Date du certificat négatif : Le ...................................... </p>
        </div>

        <div style={{ marginLeft: '40px', marginBottom: '20px', textAlign: '' }}>
          <p>2) Objet:
            {objetsSocialData.length > 0 ? (
              objetsSocialData.map((item, index) => (
                <span key={index}>{item.objetSocial}{index < objetsSocialData.length - 1 && "; "}</span>
              ))
            ) : (
              <span>No objects available</span>
            )}
          </p>

          <p> 3) Activité effectivement exercée <span style={{ marginLeft: '250px' }}>Patente n° ……………………     </span>    </p>
          <p>4) Siège social: 13, Rue Ahmed El Majjati Rés. Les Alpes, Etage N8 Quartier Maarif Casablanca</p>
          <p>5) Succursales (au Maroc ……………………………………………………………………………………………………………..……
            (Àl’étranger………….. (ville, département et pays…………………………..)</p>
          <p>6) Forme juridique de la société: <span style={{ fontWeight: '700', marginLeft: '20px' }}>SARL</span></p>
          <p>7) Capital, montant <span style={{ fontWeight: '700', marginLeft: '20px' }}>1.000.000,00 DH</span>  si capital variable, montant minimum ……………………………………………………</p>
          <p>8) ) Durée de la personne morale: <span style={{ fontWeight: '700', marginLeft: '20px' }}>99 ans</span> Date de commencement d’exploitation 2023 Date à laquelle elle doit finir 2122</p>
          <p>9)  Numéro et date du dépôt des actes et pièces de la société …………………………………………………………………</p>
          <p>10)  Brevets d’invention déposés le………………………n° de délivrance ………..Marques de fabrique, de commerce ou de service déposés le……………… sous n° ……………
            Nom, Prénoms, date et lieu de naissance, nationalité, domicile et n° CIN (1) a) des associés autres que les actionnaires et les commanditaires b) des associés ou tiers autorisés à administrer gérer et
            signer pour la société c) des gérants, membres des organes d’administration de direction ou de gestion ou des directeurs, s’il s’agit d’une personne morale, raison sociale ou dénomination, forme
            juridique, siège social, objet, numéro du registre du commerce et références du représentant permanent.
          </p>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <h4 style={{ fontWeight: '900' }}>Personnes physiques</h4><br />
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', textAlign: 'center', padding: '8px' }}>Nom et Prénoms</th>
                <th style={{ border: '1px solid black', textAlign: 'center', padding: '8px' }}>Date de naissance</th>
                <th style={{ border: '1px solid black', textAlign: 'center', padding: '8px' }}>Nationalité</th>
                <th style={{ border: '1px solid black', textAlign: 'center', padding: '8px' }}>Qualité</th>
                <th style={{ border: '1px solid black', textAlign: 'center', padding: '8px' }}>Domicile</th>
                <th style={{ border: '1px solid black', textAlign: 'center', padding: '8px' }}>CIN N°</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontSize: '14px', fontWeight: '600' }}>
                <td style={{ lineHeight: '2', border: '1px solid black', padding: '4px' }}>    {gerentsData.map((item, index) => {
                  if (!item.autre) {
                    return (
                      <span key={index}>
                        - {item.soussigne?.data?.nom} {item.soussigne?.data?.prenom} <br />
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        - {item.nom}  {item.prenom} <br />
                      </span>
                    );
                  }

                })}
                </td>
                <td style={{ lineHeight: '2', border: '1px solid black', padding: '4px' }}>{gerentsData.map((item, index) => {
                  if (!item.autre) {
                    return (
                      <span key={index}>
                        - {item.soussigne?.data?.nom} {item.soussigne?.data?.prenom} <br />
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        - {item.nom}  {item.prenom} <br />
                      </span>
                    );
                  }

                })}</td>
                <td style={{ lineHeight: '2', border: '1px solid black', padding: '4px' }}>{gerentsData.map((item, index) => {
                  if (!item.autre) {
                    return (
                      <span key={index}>
                        - {item.soussigne?.data?.dateBirth} <br />
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        - {item.date_birth}  <br />
                      </span>
                    );
                  }

                })}</td>

                <td style={{ lineHeight: '2', border: '1px solid black', padding: '4px' }}>Associé-Co-gérant <br /> Associé-Co-gérant </td>
                <td style={{ lineHeight: '2', border: '1px solid black', width:'290px', padding: '4px' }}> {productData?.siege_social
                  ? `${productData?.siege_social.address} , ${productData?.siege_social.city}`
                  : `${productData?.customSiegeSocial}, ${productData?.customVilleTribunal}`
                }</td>
                <td style={{ lineHeight: '2', border: '1px solid black', padding: '4px' }}>{gerentsData.map((item, index) => {
                  if (!item.autre) {
                    return (
                      <span key={index}>
                        - {item.soussigne?.data?.cin}  <br />
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        - {item.cin} <br />
                      </span>
                    );
                  }

                })}</td>
              </tr>

            </tbody>
          </table>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Raison sociale ou dénomination</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Forme juridique</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Siège social</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Objet</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>N° du R.C</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Représentant permanent</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <p>Utiliser intercalaire s’il y a lieu  <br />
            (1) pour les étrangers résidents au Maroc n° de la carte d’immatriculation, pour les non résidents n° du passeport ou autre pièce d’identité, en indiquant la date et le lieu de délivrance.</p>
          <br />
          <p style={{ marginLeft: '50%' }}>**********</p>
        </div>

        <div style={{ marginBottom: '20px' }}>
          Les soussignés <br />
          {gerentsData.map((item, index) => {
            if (!item.autre) {
              return (
                <span key={index}>
                  - {item.soussigne?.data?.nom} {item.soussigne?.data?.prenom} adresse personnelle {item.soussigne?.data?.villeResidence} – {item.soussigne?.data?.adresse} Qualité Associé-Cogérant <br />
                </span>
              );
            } else {
              return (
                <span key={index}>
                  - {item.nom} {item.prenom} adresse personnelle {item.address} Qualité Associé-Cogérant <br />
                </span>
              );
            }
          })}
          Certifient l’exactitude des indications portées sur la présente déclaration d’immatriculation
        </div>


        <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%' }} >
          <div>
            <p>Pièces produites……………………</p><br />
            <p style={{ border: '1px solid black', padding: '10px 60px 90px 60px', textAlign: 'center' }}>
              Cadre réservé à la légalisation de <br />
              Signatu
            </p>

          </div>



          <div style={{ textAlign: 'justify' }}>
            <p style={{ marginLeft: '10px' }}>Fait en triple exemplaire</p>
            <p style={{ marginLeft: '10px' }}>Le {new Date().toLocaleDateString('fr-FR')}</p>
            <p>Les déclarants</p>
          </div>

        </div>

      </div>
    </div>
  );
};
export default GenerateImmatriculationRcPDF;
