import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import { getLang } from '../../../environnement';
import DetailsClintDrawer from '../drawers/detailsClientDrawer';

function ClientsTable({
  header,
  data,
  onClickDeleteClient,
}) {
  const [lang] = useState('FR');
  const [openDetailsClientDrawer, setOpenDetailsClientDrawer] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const getStatus = (status) => {
    if (status === 'Validée') {
      return (
        <p className='rounded-full bg-[#CAFFBD] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#35DE0A]'>Validée</p>
      );
    } else {
      return (
        <Tooltip title={status}>
          <p className='rounded-full bg-[#E5E5E5] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#000000]'>{`${status.split(' ')[0]} ${status.split(' ')[1]} ...`}</p>
        </Tooltip>
      );
    }
  };

  const colors = ['#3498db', '#2ecc71', '#e74c3c', '#9b59b6'];

  const handleOpenDrawer = (client) => {
    setSelectedClient(client); // Set the client
    setOpenDetailsClientDrawer(true); // Open the drawer
  };

  return (
    <>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => (
              <th
                key={item}
                className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((client, index) => (
            <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
              <td>
                <div className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                  <div className='rounded-full flex justify-center items-center w-[2rem] h-[2rem]' style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }}>
                    <p className='text-white'>{client.nom.charAt(0).toUpperCase()}</p>
                  </div>
                  <p className='ml-3 max-w-14 break-words'>{client.id}</p>
                </div>
              </td>
              <td className='font-poppins font-normal text-sm/[16px] text-center'>{client.nom}</td>
              <td className='font-poppins font-normal text-sm/[16px] text-center'>{client.prenom}</td>
              <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{client.email}</td>
              <td className='font-poppins font-normal text-sm/[16px] text-center'>{client.phone}</td>
              <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(client.created_at), 'yyyy-MM-dd HH:mm')}</td>
              <td className='font-poppins font-normal text-sm/[16px] text-center'>{getStatus(client.status)}</td>
              <td className='py-5 text-center'>
                <Tooltip title={getLang(lang).clients.clientTable.consult}>
                  <IconButton onClick={() => handleOpenDrawer(client)}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={getLang(lang).clients.clientTable.delete}>
                  <IconButton onClick={() => onClickDeleteClient(client.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {openDetailsClientDrawer && selectedClient && (
        <DetailsClintDrawer
          open={openDetailsClientDrawer}
          onClose={() => setOpenDetailsClientDrawer(false)}
          client={selectedClient}
        />
      )}
    </>
  );
}

export default ClientsTable;
