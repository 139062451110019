import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

import { userSelector } from './utils/store/userSlice';
import AdminHeader from './utils/components/header';
import getEnvironement, { getLang } from './environnement';
import searchIcon from './utils/assets/icons/search-normal.png';
import PacksTable from './utils/components/tables/packsTable';
import AddPackDrawer from './utils/components/drawers/addPackDrawer';
import DeletePackConfirmDialog from './utils/components/dialog/deletePackConfirmDialog';
import UpdatePackDrawer from './utils/components/drawers/updatePackDrawer';

export default function AdminPacks() {
  const user = useSelector(userSelector);
  const [lang] = useState('FR');

  const [allPacks, setAllPacks] = useState([]);
  const [packs, setPacks] = useState([]);
  const [isEmptyPacks, setIsEmptyPacks] = useState(false);

  const [categories, setCategories] = useState([]); // Added state for categories
  const [openAddPackDrawer, setOpenAddPackDrawer] = useState(false);
  const [addPackErrors, setAddPackErrors] = useState(null);

  const [openUpdatePackDrawer, setOpenUpdatePackDrawer] = useState(false);
  const [updatePackErrors, setUpdatePackErrors] = useState(null);
  const [updatedPack, setUpdatedPack] = useState([]);

  const [openDeletePackConfirmDialog, setOpenDeletePackConfirmDialog] = useState(false);
  const [deletedPackId, setdeletedPackId] = useState(22);

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);

  // Fetch packs
  const getPacks = async () => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/packs`;
    await axios.get(url)
      .then((response) => {
        setAllPacks(response.data);
        setPacks(response.data);
        console.log(packs);
        
        if (response.data.length == 0) {
          setIsEmptyPacks(true);
        } else {
          setIsEmptyPacks(false);
        }
      })
      .then(() => setCircularProgress(false))
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch categories
  const getCategories = async () => {
    const url = `${getEnvironement().API_URL}/admin/categories`;  
    await axios.get(url)
      .then((response) => {
        setCategories(response.data);  
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to fetch categories', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  // Fetch pack details
  const getPack = async (id) => {
    setOpenBackDrop(true);
    const url = `${getEnvironement().API_URL}/admin/packs/${id}`;
    await axios.get(url)
      .then((response) => {
        setUpdatedPack(response.data);
      })
      .then(() => setOpenBackDrop(false))
      .then(() => setOpenUpdatePackDrawer(true))
      .catch((err) => {
        console.log(err);
      });
  };

  // Add a new pack
  const addPack = async (pack) => {
    const url = `${getEnvironement().API_URL}/admin/packs`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, pack, config)
      .then(() => setAddPackErrors(null))
      .then(() => setOpenAddPackDrawer(false))
      .then(() => getPacks())
      .then(() => {
        toast.success('Le pack a bien été ajouté', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        console.log(err);
        setAddPackErrors(err.response.data.errors);
      });
  };

  // Update a pack
  const updatePack = async (pack) => {
    const url = `${getEnvironement().API_URL}/admin/packs/${pack.id}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.put(url, pack, config)
      .then(() => setUpdatePackErrors(null))
      .then(() => setOpenUpdatePackDrawer(false))
      .then(() => getPacks())
      .then(() => {
        toast.success('Le pack a bien été modifié', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        console.log(err);
        setUpdatePackErrors(err.response.data.errors);
      });
  };

  // Delete a pack
  const deletePack = async (idPack) => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/packs/${idPack}`;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    await axios.delete(url, config)
      .then(() => getPacks())
      .then(() => setCircularProgress(false))
      .then(() => {
        toast.success('Le pack a bien été supprimé', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch(() => {
        getPacks();
        setCircularProgress(false);
        toast.error(getLang(lang).clients.deleteClientError, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  // Search functionality
  const handelChangeSearchInput = (e) => {
    setPacks(allPacks.filter(pack => pack.title.startsWith(e.target.value)));
  };

  useEffect(() => {
    getPacks();
    getCategories(); // Fetch categories on component mount
  }, []);

  return (
    <div className="py-4">
      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddPackDrawer
        open={openAddPackDrawer}
        onClose={() => { setOpenAddPackDrawer(false); setAddPackErrors(null); }}
        onClickAddPack={addPack}
        errors={addPackErrors}
        categories={categories} // Pass categories to AddPackDrawer
      />
      <UpdatePackDrawer
        open={openUpdatePackDrawer}
        onClose={() => { setOpenUpdatePackDrawer(false); setUpdatePackErrors(null); }}
        onClickUpdatePack={updatePack}
        pack={updatedPack}
        errors={updatePackErrors}
        categories={categories}
      />
      <DeletePackConfirmDialog
        open={openDeletePackConfirmDialog}
        onClose={() => setOpenDeletePackConfirmDialog(false)}
        onClickDeletePack={() => {
          deletePack(deletedPackId);
          setOpenDeletePackConfirmDialog(false);
        }}
      />
      <AdminHeader name={user.nom} />
      <div className="flex justify-between items-center mb-5">
        <p className="font-poppins font-medium text-2xl">Tous les packs</p>
        <Button
          variant="contained"
          onClick={() => setOpenAddPackDrawer(true)}
        >
          + Ajouter un nouveau pack
        </Button>
      </div>
      <div className="flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg">
        <img src={searchIcon} />
        <input
          type="search"
          placeholder="Chercher par Titre"
          onChange={(e) => handelChangeSearchInput(e)}
          className="bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full"
        />
      </div>
      <PacksTable
        header={[
          'Id_pack',
          'Titre',
          'Type',
          'Nbre max produits',
          'Prix',
          'Date',
          'Actions',
        ]}
        data={packs}
        onClickUpdatePack={(idPack) => getPack(idPack)}
        onClickDeletePack={(idPack) => {
          setOpenDeletePackConfirmDialog(true);
          setdeletedPackId(idPack);
        }}
      />
      {isEmptyPacks && (
        <div className="rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw">
          Aucun pack
        </div>
      )}
      {circularProgress && (
        <div className="flex justify-center p-5">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
