import React from 'react';

import { calculateMontant } from '../../helpers';
import { formatDateToFrench } from '../../helpers';
import { FormatMoneyDH  } from '../../helpers';

const StatutesPDF = ({ productData, soussignesData, gerentsData, apportsData, partsSocialData, objetsSocialData }) => {
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        lineHeight: '1.6',
        color: '#333',
        paddingRight: '20mm', paddingLeft: '20mm', paddingTop: '5mm', paddingBottom: '10mm',
        borderRadius: '5px',
        maxWidth: '100%',
        margin: 'auto',
    };

    const headerStyle = {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',

        textDecoration: 'underline',
    };
    const headParagraphStyle = {
        marginBottom: '10px',
        textAlign: 'center',
        fontWeight: 700,
    }

    const sectionTitleStyle = {
        fontWeight: 'bolder',
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '16px',
        textDecoration: 'underline',
        textAlign: 'center'
    };



    const paragraphStyle = {
        marginBottom: '10px',
        textAlign: 'justify',
    };

    const listStyle = {
        listStyleType: 'none',
        paddingLeft: '0',
    };

    const listItemStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px',
    };

    const strongTotalStyle = {
        fontWeight: 'bold',
        marginTop: '10px',
        display: 'block',
        textAlign: 'right',
    };

    const lineStyle = {
        border: 'none',
        borderTop: '1px solid #333',
        margin: '10px 0',
    };

    const partSocialTotal = partsSocialData.reduce((sum, item) => sum + item.partSocial, 0);

    // Calculate the partSocialAverage
    const partSocialAverage = partSocialTotal / partsSocialData.length;

    // Extract the duration in months from the items array
    const extractDurationInMonths = () => {
        const item = productData?.pack.items?.find(item => item.includes("mois de domiciliation"));
        if (item) {
            const match = item.match(/\d+/); // Extract the numeric part
            return match ? parseInt(match[0], 10) : 0; // Convert to integer
        }
        return 0; // Default to 0 if not found
    };
    const calculateEndDate = (startDate, monthsToAdd) => {
        const date = new Date(startDate);
        date.setMonth(date.getMonth() + monthsToAdd);
        return date.toISOString().split("T")[0]; // Return the date in YYYY-MM-DD format
    };

    const durationInMonths = extractDurationInMonths(); // Get the duration dynamically

    const formattedDate = productData?.created_at
        ? new Date(productData?.created_at).toISOString().split("T")[0]
        : "Date non spécifiée";

    const endDate = productData?.created_at
        ? calculateEndDate(productData?.created_at, durationInMonths)
        : "Date non spécifiée";

    return (
        <div className="pdf-container" style={containerStyle}>

            <div style={headerStyle}>{productData?.denominationValide}</div>
            <p style={headParagraphStyle}>
                Société à Responsabilité Limitée au capital de {FormatMoneyDH (productData?.capitalSocial)}<br />

                Siège Social : {productData?.siege_social
                    ? `${productData?.siege_social.address} , ${productData?.siege_social.city}`
                    : `${productData?.customSiegeSocial}, ${productData?.customVilleTribunal}`
                }

            </p>

            <div style={headerStyle}>STATUTS</div> <br />
            <div className="pdf-content">
                <p style={paragraphStyle}>
                    <strong> <u>LES SOUSSIGNÉS </u></strong>
                    {soussignesData.map((rep, index) => {
                        if (rep.data?.type === "Personne physique") {
                            return (
                                <span key={index}>
                                    <br /> <b>- {rep.data?.nom} {rep.data?.prenom} </b>, {rep.data?.nationalite}, né le <b>{formatDateToFrench(rep.data?.dateBirth)}</b> demeurant à {rep.data?.villeResidence} – {rep.data?.adresse} titulaire de la CIN n°<b> {rep.data?.cin} </b>;
                                   
                                </span>
                            );
                        } else if (rep.data?.type === "Personne morale") {
                            return (
                                <span key={index}>
                                  <br />  <b>- {rep.data?.denomination} </b>, ICE N° <b>{rep.data.ice} </b>, RC N° <b>{rep.data.rc} </b>,
                                    siège social au <b>{rep.data?.adresseSiege} </b>.
                                   
                                </span>
                            );
                        }
                    })}


                </p>
                <p style={paragraphStyle}>
                    Ont établi ainsi qu’il suit les statuts d’une société à responsabilité limitée qu’ils ont convenu de
                    constituer entre eux :
                </p>

                <div style={sectionTitleStyle}>
                    TITRE PREMIER : FORME - DÉNOMINATION - OBJET - SIÈGE - DURÉE
                </div>
                <p style={paragraphStyle}>
                    <strong> <u> ARTICLE 1 : FORME </u></strong>
                    <br />
                    Il est formé entre les soussignés, propriétaires par parts d’actions créés de la cité qui pourraient
                    l’être ultérieurement, une société à responsabilité limitée ayant pour objet régit par les lois en
                    vigueur et notamment le dahir n°1-97-49 du 15 Chaoual 1417 (3 Février 1997) portant promulgation de la
                    loi n°5-96 ainsi que par les présents statuts.
                </p>
                <p style={paragraphStyle}>
                    <strong> <u> ARTICLE 2 : RAISON SOCIALE </u></strong>
                    <br />
                    La raison sociale de la société est : <strong>{productData?.denominationValide} - SARL</strong>
                </p>
                <p style={paragraphStyle}>
                    <strong> <u> ARTICLE 3 : OBJET SOCIAL </u></strong>
                    <br />
                    La société a pour objet principal :
                </p>
                <ul style={listStyle}>
                    {objetsSocialData.map((item, index) => (
                        <li key={index}>- {item.objetSocial} </li>
                    ))}
                </ul>

                <div style={sectionTitleStyle}>
                    TITRE DEUXIÈME : APPORTS - CAPITAL SOCIAL - PARTS SOCIALES
                </div>
                <p style={paragraphStyle}>
                    <strong> <u> ARTICLE 4 : SIÈGE SOCIAL </u></strong>
                    <br />
                    Le siège social est fixé au : {productData?.siege_social
                        ? `${productData?.siege_social.address} , ${productData?.siege_social.city}`
                        : `${productData?.customSiegeSocial}, ${productData?.customVilleTribunal}`
                    }
                </p>
                <p style={paragraphStyle}>
                    <strong> <u> ARTICLE 5 : DURÉE DE LA SOCIÉTÉ </u></strong>
                    <br />
                    La durée de la société est fixée à <b>{productData?.dureeSociete}</b> années à compter du jour de son immatriculation au registre du
                    commerce sauf cas de dissolution anticipée ou de prorogation prévus aux présents statuts et par la loi.
                </p>

                <p style={paragraphStyle}>Les associés ont effectué les apports en numéraires suivants :</p>

                <strong> <u> 6 : APPORTS  </u></strong> <br />

                <ul style={listStyle}>
                    {apportsData.map((item, index) => (
                        <li key={index} style={listItemStyle}>
                            <span>{item.soussigne}</span>
                            <span>{FormatMoneyDH (calculateMontant(productData?.capitalSocial, item.apport))}  {item.autreApport ? - item.autreApport : ''}parts</span>
                        </li>
                    ))}

                </ul>
                <hr style={lineStyle} />
                <p style={strongTotalStyle}>Soit au total : {FormatMoneyDH (productData?.capitalSocial)}</p>

                <p style={paragraphStyle}>
                    Laquelle somme de {FormatMoneyDH (productData?.capitalSocial)} a été intégralement libérée par les associés en numéraire et déposée
                    dans la caisse de la société.
                </p>

                <p style={paragraphStyle}>
                    <strong> <u> 7 : CAPITAL SOCIAL </u></strong> <br />

                    Le capital de la société est fixé à {FormatMoneyDH (productData?.capitalSocial)} et divisé en {FormatMoneyDH (partSocialTotal)} parts sociales de {FormatMoneyDH (partSocialAverage)}
                    chacune, attribuées aux associés comme suit :
                </p>

                <ul style={listStyle}>
                    {partsSocialData.map((item, index) => (

                        <li key={index} style={listItemStyle}>
                            <span>{item.soussigne}</span>
                            <span>{FormatMoneyDH (item.partSocial)} parts</span>
                        </li>
                      ))}

                </ul>
                <hr style={lineStyle} />
                <p style={strongTotalStyle}>Soit au total : {FormatMoneyDH (partSocialTotal)} parts</p>
                <br />
                <p style={paragraphStyle}>
                    <strong> <u> 8 : PARTS SOCIALES  </u></strong> <br />

                    Les parts sociales représentent la contrepartie d'un apport en numéraires ou en nature.
                </p>

                <p style={paragraphStyle}>
                    <strong> <u> 9 : REPRESENTATION DES PARTS SOCIALES  </u></strong> <br />

                    Les parts sociales ne peuvent jamais être représentées par des titres négociables.
                    Les droits de chaque associé dans la société résultent seulement des présentes, des actes modificatifs
                    ultérieurs et des cessions de parts régulièrement consenties.
                </p>

                <p style={paragraphStyle}>
                    <strong> <u> 10 : TRANSMISSION DES PARTS SOCIALES </u></strong> <br />

                    Toute cession de parts sociales doit être constatée par un acte sous seing privé ou notarié.
                    Les parts sociales sont librement cessibles entre associés, conjoints, parents et alliés jusqu'au deuxième
                    degré inclusivement. Elles sont également librement transmissibles par voie de succession.
                    Les parts ne peuvent être cédées à des tiers qu’avec le consentement de la majorité des associés,
                    représentant au moins les trois quarts des parts sociales.
                </p>
                <p style={paragraphStyle}>
                    <strong> <u> 11 : CLAUSE D’AGREMENT </u></strong> <br />

                    Le projet de cession à des tiers est notifié à la société et à chacun des associés par lettre recommandée
                    avec accusé de réception (ou par huissier de justice). La société a un délai de 30 jours pour faire
                    connaître son droit de revendication à compter de la date de la dernière des notifications adressée aux
                    associés. Passé ce délai, le consentement est réputé acquis.
                    Dans le délai de huit jours à compter de cette notification, le gérant doit convoquer l’Assemblée des
                    associés pour qu’elle délibère sur le projet de cession de parts sociales, ou consulter les associés par
                    écrit sur ledit projet. La décision prise par l’Assemblée n’a pas à être motivée.
                    La décision de la société est notifiée au cédant par lettre recommandée avec demande d’avis de
                    réception dans un délai de trente jours.
                    Si le consentement demandé lui est accordé, l’associé pourra céder les parts visées dans sa demande à
                    la personne ou aux personnes désignées par lui.
                    Si la société a refusé de consentir à la cession, les associés sont tenus dans le délai de trente jours à
                    compter de ce refus, d'acquérir ou de faire acquérir les parts à un prix fixé à dire d'expert.
                    A la demande du gérant, ce délai peut être prolongé une seule fois par ordonnance du président du
                    tribunal, statuant en référé, sans que cette prolongation puisse excéder trois mois.
                    Si la société a donné son consentement à un projet de nantissement de parts sociales, ce
                    consentement emportera agrément en cas de réalisation forcée des parts nanties, à moins que la société
                    ne préfère après la cession, racheter sans délai les parts en vue de réduire son capital.
                </p>

                <div style={sectionTitleStyle}>
                    TITRE TROISIEME : ADMINISTRATION DE LA SOCIETE
                </div>
                <p style={paragraphStyle}>
                    <strong> <u> 12 : NOMINATION - DUREE ET POUVOIRS DE LA GERANCE  </u></strong> <br />

                    La société est administrée par un ou plusieurs gérants, personne physique, associés ou non. Le ou les
                    gérants sont nommé(s) par les associés dans les statuts ou dans un acte postérieur par une décision
                    collective statuant à la majorité des trois-quarts du capital social pour une durée, limitée ou non, fixée
                    dans la décision de nomination.
                    La gérance jouit vis-à-vis des tiers, sans aucune exception ou réserve, des pouvoirs les plus étendus
                    pour agir au nom de la société et accomplir tous actes relatifs à son objet, sous réserve des pouvoirs
                    que la loi attribue expressément aux associés. <br />
                    Dans les rapports entre associés, la gérance dispose des mêmes pouvoirs qu’à l’égard des tiers.
                </p>

                <div style={sectionTitleStyle}>
                    TITRE QUATRIEME : DECISIONS COLLECTIVES DES ASSOCIES
                </div>

                <p style={paragraphStyle}>
                    <strong> <u> 13 : DECISIONS DE NATURE ORDINAIRE  </u></strong> <br />

                    Les décisions collectives de nature ordinaire ont notamment pour objet :
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>
                            de donner à la gérance les autorisations nécessaires pour accomplir les actes excédant les pouvoirs
                            qui lui ont été conférés ;
                        </li>
                        <li>
                            de statuer sur les comptes d'un exercice et sur l'affectation et la répartition des bénéfices ;
                        </li>
                        <li>d'examiner les conventions réglementées ;</li>
                        <li>
                            et, d'une manière générale, de se prononcer sur toutes les questions autres que celles réputées de
                            nature extraordinaire mentionnées à l’article 21.
                        </li>
                    </ul>
                    Elles ne sont valablement prises sur première consultation qu'autant qu’elles ont été adoptées par un
                    ou plusieurs associés représentant plus de la moitié des parts sociales. Si cette majorité n'est pas
                    atteinte à la première consultation les associés sont réunis ou consultés une seconde fois et les
                    décisions sont alors valablement prises à la majorité des votes émis, quel que soit le nombre des
                    votants, à la condition expresse de ne porter que sur les questions ayant fait l'objet de la première
                    consultation.
                    Par dérogation à la règle ci-dessus énoncée, doivent être adoptées par les associés statuant à la majorité
                    des trois-quarts du capital social, les décisions portant nomination ou révocation du ou des gérants.
                </p>
                <p style={paragraphStyle}>
                    <strong> <u> 14 : DECISIONS DE NATURE EXTRAORDINAIRE  </u></strong> <br />

                    Les décisions collectives de nature extraordinaire sont celles appelées à se prononcer sur toutes
                    questions comportant modification des statuts et notamment la transformation, la prorogation, la
                    dissolution anticipée de la société, l’agrément des cessions et la transmission de parts sociales.
                    Les décisions collectives extraordinaires emportant modification des statuts ne sont valablement prises
                    qu'autant qu'elles ont été adoptées par des associés représentant au moins les trois quarts des parts
                    sociales.
                    Par dérogation à la règle ci-dessus énoncée, notamment :
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>
                            doit être adoptée avec l'accord unanime des associés, toute décision de transformation en société en
                            nom collectif ;
                        </li>
                        <li>
                            doit être adoptée avec l'accord unanime des futurs associés commandités et à la majorité des trois-
                            quarts des futurs associés commanditaires, toute décision de transformation en société en
                            commandite simple ou par actions ;
                        </li>
                        <li>
                            doit être adoptée à la majorité des associés représentant les trois-quarts du capital social, toute décision
                            portant agrément des cessions et transmissions de parts sociales à des tiers autres que ceux cités au
                            deuxième alinéa de l’article 10 ;
                        </li>
                        <li>
                            doit être adoptée avec l'accord unanime des associés, toute décision d’augmentation de capital par
                            élévation de la valeur nominale des parts sociales ;
                        </li>
                        <li>
                            doit être adoptée par des associés représentant au moins la moitié des parts sociales, toute incorporation
                            au capital social de bénéfices, de primes ou de réserves.
                        </li>
                    </ul>
                </p>

                <p style={paragraphStyle}>
                    <strong> <u> ARTICLE 15 : TENUE DES ASSEMBLEES  </u></strong> <br />
                    L'assemblée des associés est présidée par le gérant ou par le plus âgé des gérants présents. Si aucun
                    des gérants n'est associé, elle est présidée par l'associé présent et acceptant qui possède ou représente
                    le plus grand nombre de parts sociales. <br />
                    Si deux associés possédant ou représentant le même nombre de parts acceptent la présidence de
                    l'assemblée, celle-ci est assurée par le plus âgé. <br />
                    Un secrétaire de séance associé ou non peut être désigné par le président. <br />
                    Seules peuvent être mises en délibération les questions figurant à l'ordre du jour. <br />
                    Chaque associé a le droit de participer aux décisions et dispose d'un nombre de voix égal à celui des
                    parts sociales qu'il possède, même si ses parts sont frappées de saisie-arrêt, mises sous séquestre ou
                    données en nantissement.  <br />
                    Pour le calcul de la majorité en nombre, les copropriétaires indivis de parts sociales ne comptent que
                    pour un associé. Pour le même calcul, l'usufruitier et le nu-propriétaire ne comptent également que
                    pour un associé. <br />
                    Le droit de vote est incessible et la représentation d’un associé aux assemblées s’effectue conformément
                    aux dispositions de l’article 17. <br />
                    Le mandat s'applique obligatoirement à la totalité des voix dont dispose le mandant. Le mandat donné
                    pour une assemblée est toujours réputé donné pour les assemblées successives convoquées sur le
                    même ordre du jour. <br />
                    Les représentants légaux d'associés juridiquement incapables peuvent participer à tous les votes sans
                    être par eux-mêmes associés, sauf à justifier de leur qualité sur la demande de la gérance.
                </p>

                <p style={paragraphStyle}>
                    <strong> <u> 16 : PROCES-VERBAUX DES DECISIONS </u></strong> <br />

                    Toute délibération de l'assemblée des associés est constatée par un procès-verbal qui mentionne la date
                    et le lieu de la réunion, les prénoms, nom et qualité du président, les prénoms, noms des associés
                    présents ou représentés avec l'indication du nombre de parts sociales détenues par chacun d’eux, les
                    documents et rapports soumis à l’assemblée, un résumé des délibérations, les projets de résolutions
                    soumises au vote et le résultat du vote. <br />
                    En cas de consultation écrite, il est fait mention dans le procès-verbal des modalités de cette
                    consultation. La réponse de chaque associé est annexée au procès-verbal. <br />
                    Les procès-verbaux sont établis et signés par le président. Ils sont inscrits et enliassés dans un registre
                    spécial tenu au siège social et coté et paraphé conformément aux prescriptions légales. <br />
                    Les copies ou extraits des procès-verbaux des délibérations des associés sont valablement certifiés
                    conformes par un seul gérant. Au cours de la liquidation de la société, leur certification est valablement
                    effectuée par un seul liquidateur.
                </p>

                <div style={sectionTitleStyle}>
                    TITRE CINQUIEME : EXERCICE SOCIAL - COMPTES SOCIAUX - AFFECTATION ET REPARTITION DES RESULTATS
                </div>

                <p style={paragraphStyle}>
                    <strong><u>17 : EXERCICE SOCIAL - COMPTES SOCIAUX</u></strong><br />

                    Chaque exercice social a une durée d'une année, qui commence le <b>{formatDateToFrench(formattedDate, false) || '1er janvier'}</b> et se termine le <b>{formatDateToFrench(endDate, false) || '31 décembre'}</b>.
                    Par exception, le premier exercice commencera le jour de l'immatriculation de la société au registre du commerce
                    (<b>{formatDateToFrench(formattedDate)}</b>) et se terminera le <b>{formatDateToFrench(endDate)}</b>. À la clôture de chaque exercice, la gérance dressera
                    l'inventaire de la société ainsi que les états de synthèse et établira un rapport de gestion sur la situation de
                    la société.
                </p>

                <p style={paragraphStyle}>
                    <strong> <u> 18 : AFFECTATION ET REPARTITION DES BENEFICES </u></strong> <br />

                    Le résultat de l’exercice correspond à la différence entre les productDatas et les charges de l'exercice après
                    déduction des amortissements et des provisions. <br />
                    Sur ce bénéfice, diminué éventuellement des pertes antérieures, sont prélevées les sommes à porter en
                    réserve en application de la loi, et en particulier à peine de nullité de toute délibération contraire, une
                    somme correspondant à un vingtième pour constituer le fonds de réserve légale. Ce prélèvement cesse
                    d'être obligatoire lorsque le fonds de réserve atteint le cinquième du capital social. <br />
                    Ce bénéfice est réparti entre tous les associés proportionnellement au nombre de parts appartenant à
                    chacun d'eux. <br />
                    L'assemblée générale peut décider la distribution de sommes prélevées sur les réserves dont elle a la
                    disposition en indiquant expressément les postes de réserves sur lesquels les prélèvements ont été
                    effectués. Toutefois, les dividendes sont prélevés par priorité sur le bénéfice distribuable de l’exercice.
                </p>
                <div style={sectionTitleStyle}>
                    TITRE SIXIEME : DISPOSITIONS DIVERSES
                </div>

                <p style={paragraphStyle}>
                    <strong> <u> 19 – NOMINATION DES GOGERANTS </u></strong> <br />

                    Sont nommés en qualité de co-gérants de la société pour une durée illimitée : <br />
                    {gerentsData.map((item, index) => {
                        if (!item.autre) {
                            return (
                                <span key={index}>

                                    <b>- {item.soussigne?.data?.nom} {item.soussigne?.data?.prenom} </b>, {item.soussigne?.data?.nationalite}, né le {formatDateToFrench(item.soussigne?.data?.dateBirth)} demeurant à {item.soussigne?.data?.villeResidence} – {item.soussigne?.data?.adresse} et titulaire de la CIN n° <b>{item.soussigne?.data?.cin}</b> ; <br />
                                 
                                </span>
                            );
                        } else {
                            return (
                                <span key={index}>
                                 <b>- {item.nom} {item.prenom} </b>, {item.nationalite}, né le {formatDateToFrench(item.date_birth)} demeurant à {item.villeResidence} – {item.adresse} et titulaire de la CIN n° <b>{item.cin}</b> ; <br />
                                   
                                </span>
                            );
                        }

                    })}

                    Les co-gérants sont investis des pouvoirs les plus étendus pour agir et signer {
                        productData?.pouvoirs === 'ensemble' ? 'conjointement' : 'séparément'} au nom de la société dans la limite de l’objet social de la société.

                </p>

                <p style={paragraphStyle}>
                    <strong> <u> 20 – GREFFE DU TRIBUNAL </u></strong> <br />

                    Les statuts seront déposés au greffe du tribunal de Commerce de <strong>{productData?.siege_social
                        ? productData.siege_social.city
                        : `${productData?.customVilleTribunal}`
                    }</strong>. <br />

                    <p>Fait à Casablanca le <span style={{ textDecoration: "underline" }}>..................</span></p>

                </p>
            </div>
            <div className='pdf-content'
                style={{
                    width: "100%",
                    marginTop: "40px",
                    textAlign: "center",
                    fontSize: "14px",
                    lineHeight: "1.8",
                }}
            >
                <div style={sectionTitleStyle}>LES ASSOCIES</div>
                <p> <b>Et pour acceptation des fonctions de cogérants </b></p>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>


                    {gerentsData.map((item, index) => {
                        if (!item.autre) {
                            return (
                                <p key={index}>
                                    <strong >{item.soussigne?.data?.nom} {item.soussigne?.data?.prenom} </strong>
                                </p>
                            );
                        } else {
                            return (
                                <p key={index}>
                                    <strong >{item.nom} {item.prenom} </strong>
                                </p>
                            );
                        }

                    })}

                </div>
            </div>


        </div>

    );
};

export default StatutesPDF;
