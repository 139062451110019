import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, CircularProgress, InputAdornment } from '@mui/material';
import PaymentsTable from './utils/components/tables/paymentsTable';
import AdminHeader from './utils/components/header';
import { userSelector } from './utils/store/userSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import getEnvironement from './environnement';
import searchIcon from './utils/assets/icons/search-normal.png';
import { Bounce, toast, ToastContainer } from 'react-toastify';

const Payments = () => {
    const user = useSelector(userSelector);
    const [payments, setPayments] = useState([]);
    const [allPayments, setAllPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch payments
    const fetchPayments = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${getEnvironement().API_URL}/admin/payments`);
            setPayments(response.data);
            setAllPayments(response.data);
        } catch (error) {
            console.error('Failed to fetch payments:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const validatePayment = async (paymentId) => {
        try {
            await axios.post(`${getEnvironement().API_URL}/admin/payments/validate/${paymentId}`);
            toast.success('Payment validated successfully!', {
                position: 'top-right',
                autoClose: 3000,
                theme: 'light',
                transition: Bounce,
            });
            fetchPayments(); // Refresh payments after validation
        } catch (error) {
            console.error(error);
            toast.error('Failed to validate payment. Please try again.', {
                position: 'top-right',
                autoClose: 5000,
                theme: 'light',
                transition: Bounce,
            });
        }
    };


    // Search functionality
    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        const filteredPayments = allPayments.filter(
            (payment) =>
                payment.reference.toLowerCase().includes(query) ||
                payment.userName.toLowerCase().includes(query)
        );
        setPayments(filteredPayments);
    };

    useEffect(() => {
        fetchPayments();
    }, []);

    return (
        <Container>
            <ToastContainer />
            <Box sx={{ marginTop: 2 }}>
                <AdminHeader name={user.nom} />
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    Gestion des paiements
                </Typography>

                {/* Search Input */}
                <div className="flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg">
                    <img src={searchIcon} />
                    <input
                        type="search"
                        placeholder="Chercher par Référence | Utilisateur"
                        onChange={handleSearch}
                        className="bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full"
                    />
                </div>

                {/* Payments Table */}
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    <PaymentsTable payments={payments} validatePayment={validatePayment} />
                )}
            </Box>
        </Container>
    );
};

export default Payments;
