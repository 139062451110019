
import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import getEnvironement, { getLang } from '../../../environnement';
import app_logo from '../../assets/images/LOGO-LS@2x.png';

function DomiciliationCentersTable({
  header,
  data,
  onClickDeleteDomiciliationCenter,
  onClickUpdateDomiciliationCenter

}) {
  const [lang] = useState('FR');
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const openDrawer = (center) => {
    setSelectedCenter(center);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedCenter(null);
    setIsDrawerOpen(false);
  };
  const colors = ['#3498db', '#2ecc71', '#e74c3c', '#9b59b6'];
  return (
    <>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((center, index) => {
            return (
              <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
                <td>
                  <div className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>

                    {center.logo ? (
                      <div style={{ textAlign: "center", marginBottom: "20px" }}>
                        <img
                          src={`${getEnvironement().BACKEND_URL}/storage/app/public/${center.logo}`}
                          alt={`${center.name} logo`}
                          style={{ maxWidth: "80px", maxHeight: "80px", objectFit: "contain" }}
                        />
                      </div>
                    ) : (
                      <div
                        className='rounded-full flex justify-center items-center w-[2rem] h-[2rem]'
                        style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }}
                      >
                        <p className='text-white'>
                          {center.name.charAt(0).toUpperCase()}
                        </p>
                      </div>
                    )}

                    <p className='ml-3 max-w-14 break-words'>{center.id}</p>
                  </div>
                </td>

                <td className='font-poppins font-normal text-sm/[16px] text-center'>{center.name}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{center.email}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{center.phone}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(center.created_at), 'yyyy-MM-dd HH:mm')}</td>
                <td className='py-5 text-center'>
                  <Tooltip title={getLang(lang).domiciliationCenters.centerTable.consult}>
                    <IconButton onClick={() => openDrawer(center)} >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={getLang(lang).domiciliationCenters.centerTable.update}>
                    <IconButton onClick={() => onClickUpdateDomiciliationCenter(center.id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={getLang(lang).domiciliationCenters.centerTable.delete}>
                    <IconButton onClick={() => onClickDeleteDomiciliationCenter(center.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>

                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Drawer for Details */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={closeDrawer}>
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
            <div className='flex w-full justify-between items-center'>
              <img src={app_logo} alt='logo' className='w-[300px]' />
              <IconButton onClick={closeDrawer}>
                <CloseIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </div>
          </div>
          <div style={{ width: '50vw', padding: '16px' }}>

            <h2 className='font-poppins font-medium text-2xl mb-5'>Center Details</h2>
            {selectedCenter ? (
              <div>
                <hr />
                {/* Display the logo at the top */}
                {selectedCenter.logo && (
                  <div className='flex justify-center my-5'>
                    <img
                      src={`${getEnvironement().BACKEND_URL}/storage/app/public/${selectedCenter.logo}`}
                      alt={`${selectedCenter.name} logo`}
                      style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "contain" }}
                    />
                  </div>
                )}
                <hr className='mb-5 ' />
                <p><strong>ID:</strong> {selectedCenter.id}</p>
                <p><strong>Name:</strong> {selectedCenter.name}</p>
                <p><strong>City:</strong> {selectedCenter.city}</p>
                <p><strong>Address:</strong> {selectedCenter.address}</p>
                <p><strong>Phone:</strong> {selectedCenter.phone}</p>
                <p><strong>Fax:</strong> {selectedCenter.fax}</p>
                <p><strong>Email:</strong> {selectedCenter.email}</p>
                <p><strong>Nom du responsable:</strong> {selectedCenter.manager}</p>
                <p><strong>CIN du responsable:</strong> {selectedCenter.cin_manager}</p>
                <p><strong>Address du responsable:</strong> {selectedCenter.address_manager}</p>
                <p><strong>Date de naissance du responsable:</strong> {selectedCenter.date_birth_manager}</p>
                <p><strong>RC:</strong> {selectedCenter.rc}</p>
                <p><strong>Patente:</strong> {selectedCenter.patente}</p>
                <p><strong>IF:</strong> {selectedCenter.ifCode}</p>
                <p><strong>CNSS:</strong> {selectedCenter.cnss}</p>
                <p><strong>ICE:</strong> {selectedCenter.ice}</p>
                <p><strong>Created At:</strong> {new Date(selectedCenter.created_at).toLocaleString()}</p>
                <p><strong>Updated At:</strong> {new Date(selectedCenter.updated_at).toLocaleString()}</p>
              </div>

            ) : (
              <p>No details available</p>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default DomiciliationCentersTable;