import React, { useState, useEffect } from 'react';
import { IconButton, Button, Grid, Drawer } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import getEnvironement from '../../../environnement';
import CloseIcon from '@mui/icons-material/Close';
import app_logo from '../../assets/images/LOGO-LS@2x.png';

const UpdateCategoryDrawer = ({ open, onClose, onCategoryUpdated, selectedCategory, errors }) => {
    const [categoryName, setCategoryName] = useState('');
    const [categoryTitle, setCategoryTitle] = useState('');
    const [error, setError] = useState('');

    // Set the selected category's data when the drawer is opened
    useEffect(() => {
        if (open && selectedCategory) {
            setCategoryName(selectedCategory.name);
            setCategoryTitle(selectedCategory.title);
        }
    }, [open, selectedCategory]);

    const handleUpdate = async () => {
        try {
            const response = await axios.put(`${getEnvironement().API_URL}/admin/categories/${selectedCategory.id}`, {
                name: categoryName,
                title: categoryTitle,
            });
            onCategoryUpdated(response.data); // Pass the updated category to the parent
            onClose(); // Close the drawer
        } catch (err) {
            setError('Error updating category');
        }
    };

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDrawer-paper': { width: '70wv', maxWidth: '90wv' },
            }}
        >
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                    <div className='flex w-full justify-between items-center'>
                        <img src={app_logo} alt='logo' className='w-[300px]' />
                        <IconButton onClick={onClose}>
                            <CloseIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ padding: '90px  20px 20px 20px', width: '50vw' }}>

                    <h2 className='font-poppins font-medium text-2xl mb-5'>Modifier la catégorie</h2>
                    <TextField
                        sx={{ marginTop: '16px' }}
                        label="Nom de la catégorie"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        fullWidth
                        margin="dense"
                        error={!!error || (errors && errors.name)}
                        helperText={errors?.name || error}
                    />
                    <TextField
                        sx={{ marginTop: '16px' }}
                        label="Titre de la catégorie"
                        value={categoryTitle}
                        onChange={(e) => setCategoryTitle(e.target.value)}
                        fullWidth
                        margin="dense"
                        error={!!error || (errors && errors.title)}
                        helperText={errors?.title || error}
                    />
                    <Grid item xs={12} container spacing={2} sx={{ marginTop: '16px' }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={onClose}
                                fullWidth
                            >
                                Annuler
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handleUpdate} variant="contained" size="large">
                                Mettre à jour
                            </Button>

                        </Grid>
                    </Grid>

                </div>
            </div>

        </Drawer >
    );
};

export default UpdateCategoryDrawer;
