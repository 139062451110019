import React, { useEffect, useState } from 'react';
import { IconButton, Button, createTheme, Grid, TextField, ThemeProvider, Drawer } from '@mui/material';
import { getLang } from '../../../environnement';

import CloseIcon from '@mui/icons-material/Close';
import app_logo from '../../assets/images/LOGO-LS@2x.png';

export default function AddClientDrawer({
  onClose,
  open,
  onClickAddClient,
  errors,
}) {
  const [lang] = useState('FR');

  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    setNom('');
    setPrenom('');
    setEmail('');
    setPhone('');
    setBirthDate('');
    setPassword('');
    setConfirmPassword('');
  }, [open]);

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': { width: '70wv', maxWidth: '90wv' },
        }}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
            <div className='flex w-full justify-between items-center'>
              <img src={app_logo} alt='logo' className='w-[300px]' />
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </div>
          </div>
          <div style={{ padding: '90px  20px 20px 20px', width: '50vw' }}>

            <h2 className='font-poppins font-medium text-2xl mb-5'>{getLang(lang).clients.addClientsDialog.addNewClient}</h2>
            <Grid container spacing={2}>
              {/* nom */}
              <Grid item xs={6}>
                <TextField
                  id="nom"
                  label={getLang(lang).clients.addClientsDialog.lastName}
                  variant="outlined"
                  margin="dense"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  error={errors && errors.nom}
                  helperText={errors && errors.nom ? errors.nom : ''}
                  fullWidth
                />
              </Grid>
              {/* prénom */}
              <Grid item xs={6}>
                <TextField
                  id="prenom"
                  label={getLang(lang).clients.addClientsDialog.firstName}
                  variant="outlined"
                  margin="dense"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  error={errors && errors.prenom}
                  helperText={errors && errors.prenom ? errors.prenom : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* email */}
              <Grid item xs={6}>
                <TextField
                  id="email"
                  type="email"
                  label={getLang(lang).clients.addClientsDialog.email}
                  variant="outlined"
                  margin="dense"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={errors && errors.email}
                  helperText={errors && errors.email ? errors.email : ''}
                  fullWidth
                />
              </Grid>
              {/* phone */}
              <Grid item xs={6}>
                <TextField
                  id="phone"
                  type="tel"
                  label={getLang(lang).clients.addClientsDialog.phone}
                  variant="outlined"
                  margin="dense"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={errors && errors.phone}
                  helperText={errors && errors.phone ? errors.phone : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* birthDate */}
            <Grid container>
              <TextField
                id="birthDate"
                type="date"
                label={getLang(lang).clients.addClientsDialog.birthDate}
                variant="outlined"
                margin="dense"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                error={errors && errors.birthDate}
                helperText={errors && errors.birthDate ? errors.birthDate : ''}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container spacing={2}>
              {/* password */}
              <Grid item xs={6}>
                <TextField
                  id="password"
                  type="password"
                  label={getLang(lang).clients.addClientsDialog.password}
                  variant="outlined"
                  margin="dense"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={errors && errors.password}
                  helperText={errors && errors.password ? errors.password : ''}
                  fullWidth
                />
              </Grid>
              {/* confirmPassword */}
              <Grid item xs={6}>
                <TextField
                  id="confirmPassword"
                  type="password"
                  label={getLang(lang).clients.addClientsDialog.confirmPassword}
                  variant="outlined"
                  margin="dense"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={errors && errors.password_confirmation}
                  helperText={errors && errors.password_confirmation ? errors.password_confirmation : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={onClose}
                  fullWidth
                >
                  {getLang(lang).clients.addClientsDialog.cancel}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    onClickAddClient({
                      nom,
                      prenom,
                      email,
                      phone,
                      birthDate,
                      password,
                      password_confirmation: confirmPassword,
                    })
                  }
                  fullWidth
                >
                  {getLang(lang).clients.addClientsDialog.add}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {

    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        },
      },
    },
  },
});
