import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

import AdminHeader from './utils/components/header';
import DomiciliationCentersTable from './utils/components/tables/domiciliationCentersTable';
import getEnvironement, { getLang } from './environnement';
import AddDomiciliationCenterDrawer from './utils/components/drawers/addDomiciliationCenterDrawer';
import DeleteDomiciliationCenterConfirmDialog from './utils/components/dialog/deleteDomiciliationCenterConfirmDialog';
import UpdateDomiciliationCenterDrawer from './utils/components/drawers/updateDomiciliationCenterDrawer';
import searchIcon from './utils/assets/icons/search-normal.png';
import { userSelector } from './utils/store/userSlice';

export default function AdminDomiciliationCenters() {
    const user = useSelector(userSelector);
    const [lang] = useState('FR');

    const [allDomiciliationCenters, setAllDomiciliationCenters] = useState([]);
    const [domiciliationCenters, setDomiciliationCenters] = useState([]);
    const [isEmptyDomiciliationCenters, setIsEmptyDomiciliationCenters] = useState(false);

    const [openAddDomiciliationCenterDrawer, setOpenAddDomiciliationCenterDrawer] = useState(false);
    const [addDomiciliationCenterErrors, setAddDomiciliationCenterErrors] = useState(null);

    const [openUpdateDomiciliationCenterDrawer, setOpenUpdateDomiciliationCenterDrawer] = useState(false);
    const [updateDomiciliationCenterErrors, setUpdateDomiciliationCenterErrors] = useState(null);
    const [updatedDomiciliationCenter, setUpdatedDomiciliationCenter] = useState([]);

    const [openDeleteDomiciliationCenterConfirmDialog, setOpenDeleteDomiciliationCenterConfirmDialog] = useState(false);
    const [deletedDomiciliationCenterId, setDeletedDomiciliationCenterId] = useState(22);

    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);






    const getDomiciliationCenters = async () => {
        setCircularProgress(true);
        const url = `${getEnvironement().API_URL}/admin/domiciliation-centers`;
        const params = { managerId: user.id };
        await axios.get(url, { params })
            .then((response) => {
                setAllDomiciliationCenters(response.data);
                setDomiciliationCenters(response.data);
                if (response.data.length === 0) {
                    setIsEmptyDomiciliationCenters(true);
                } else {
                    setIsEmptyDomiciliationCenters(false);
                }
            })
            .then(() => setCircularProgress(false))
            .catch((err) => {
                console.log(err);
            });
    };

    const getDomiciliationCenter = async (id) => {
        setOpenBackDrop(true);
        const url = `${getEnvironement().API_URL}/admin/domiciliation-centers/${id}`;
        await axios.get(url)
            .then((response) => {
                setUpdatedDomiciliationCenter(response.data);
            })
            .then(() => setOpenBackDrop(false))
            .then(() => setOpenUpdateDomiciliationCenterDrawer(true))
            .catch((err) => {
                console.log(err);
            });
    };

    const addDomiciliationCenter = async (domiciliationCenter) => {
        const url = `${getEnvironement().API_URL}/admin/domiciliation-centers`;
        const formData = new FormData();

        // Append each field to the FormData object
        for (const key in domiciliationCenter) {
            if (domiciliationCenter[key] instanceof File) {
                formData.append(key, domiciliationCenter[key]);
            } else {
                formData.append(key, domiciliationCenter[key]);
            }
        }

        formData.append('manager', user.id);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await axios.post(url, formData, config)
            .then(() => setAddDomiciliationCenterErrors(null))
            .then(() => setOpenAddDomiciliationCenterDrawer(false))
            .then(() => getDomiciliationCenters())
            .then(() => {
                toast.success(getLang(lang).domiciliationCenters.addCenterSuccess, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
            })
            .catch((err) => {
                console.log(err);
                setAddDomiciliationCenterErrors(err.response.data.errors);
            });
    };


    const updateDomiciliationCenter = async (domiciliationCenter) => {
        const url = `${getEnvironement().API_URL}/admin/domiciliation-centers/${domiciliationCenter.id}`;
        const formData = new FormData();

        // Append each field to the FormData object
        for (const key in domiciliationCenter) {
            if (domiciliationCenter[key] instanceof File) {
                formData.append(key, domiciliationCenter[key]);
            } else {
                formData.append(key, domiciliationCenter[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await axios.post(url, formData, config)
            .then(() => setUpdateDomiciliationCenterErrors(null))
            .then(() => setOpenUpdateDomiciliationCenterDrawer(false))
            .then(() => getDomiciliationCenters())
            .then(() => {
                toast.success(getLang(lang).domiciliationCenters.updateDomiciliationCenterSuccess, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
            })
            .catch((err) => {
                console.log(err);
                setUpdateDomiciliationCenterErrors(err.response?.data?.errors || err.message);
            });
    };









    const deleteDomiciliationCenter = async (idDomiciliationCenter) => {
        setCircularProgress(true);
        const url = `${getEnvironement().API_URL}/admin/domiciliation-centers/${idDomiciliationCenter}`;
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        await axios.delete(url, config)
            .then(() => getDomiciliationCenters())
            .then(() => setCircularProgress(false))
            .then(() => {
                toast.success(getLang(lang).domiciliationCenters.deleteDomiciliationCenterSuccess, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
            })
            .catch(() => {
                getDomiciliationCenters();
                setCircularProgress(false);
                toast.error(getLang(lang).domiciliationCenters.deleteDomiciliationCenterError, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                });
            });
    };

    const handelChangeSearchInput = (e) => {
        setDomiciliationCenters(allDomiciliationCenters.filter(center => center.name.startsWith(e.target.value)));
    };

    useEffect(() => {
        getDomiciliationCenters();
    }, []);

    return (
        <div className='py-4 w-full'>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <AddDomiciliationCenterDrawer
                open={openAddDomiciliationCenterDrawer}
                onClose={() => { setOpenAddDomiciliationCenterDrawer(false); setAddDomiciliationCenterErrors(null); }}
                onClickAddDomiciliationCenter={addDomiciliationCenter}
                idDomiciliationCenter={deletedDomiciliationCenterId}
                errors={addDomiciliationCenterErrors}
            />
            <UpdateDomiciliationCenterDrawer
                open={openUpdateDomiciliationCenterDrawer}
                onClose={() => { setOpenUpdateDomiciliationCenterDrawer(false); setUpdateDomiciliationCenterErrors(null); }}
                onClickUpdateDomiciliationCenter={updateDomiciliationCenter}
                center={updatedDomiciliationCenter}
                errors={updateDomiciliationCenterErrors}
            />
            <DeleteDomiciliationCenterConfirmDialog
                open={openDeleteDomiciliationCenterConfirmDialog}
                onClose={() => setOpenDeleteDomiciliationCenterConfirmDialog(false)}
                onClickDeleteDomiciliationCenter={() => {
                    deleteDomiciliationCenter(deletedDomiciliationCenterId);
                    setOpenDeleteDomiciliationCenterConfirmDialog(false);
                }}
            />
            <AdminHeader name={user.nom} />
            <div className='flex justify-between items-center mb-5'>
                <p className='font-poppins font-medium text-2xl'>{getLang(lang).domiciliationCenters.allDomiciliationCenters}</p>
                <Button
                    variant='contained'
                    onClick={() => setOpenAddDomiciliationCenterDrawer(true)}
                >
                    {getLang(lang).domiciliationCenters.addNewCenter}
                </Button>
            </div>
            <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
                <img src={searchIcon} />
                <input
                    type='search'
                    placeholder={getLang(lang).domiciliationCenters.searchByName}
                    className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
                    onChange={handelChangeSearchInput}
                />
            </div>
            <div>
                {isEmptyDomiciliationCenters ? (
                    <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw'>{getLang(lang).domiciliationCenters.emptyDomiciliationCenters}</div>
                ) : (
                    <DomiciliationCentersTable
                        header={[
                            getLang(lang).domiciliationCenters.tableHeader.idCenter,
                            getLang(lang).domiciliationCenters.tableHeader.name,
                            getLang(lang).domiciliationCenters.tableHeader.email,
                            getLang(lang).domiciliationCenters.tableHeader.phone,
                            getLang(lang).domiciliationCenters.tableHeader.date,
                            getLang(lang).domiciliationCenters.tableHeader.actions
                        ]}
                        data={domiciliationCenters}
                        onClickUpdateDomiciliationCenter={getDomiciliationCenter}
                        onClickDeleteDomiciliationCenter={(id) => { setDeletedDomiciliationCenterId(id); setOpenDeleteDomiciliationCenterConfirmDialog(true); }}
                    />
                )}
                {circularProgress && (
                    <div className='flex justify-center p-5'>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </div>
    );
}
