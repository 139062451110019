import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import { setUser, userSelector } from '../../utils/store/userSlice';
import BottomBar from './bottomBar';
import { getLang } from '../../environnement';
import logo from '../assets/images/LOGO-LS@2x.png'

function AdminSidebar({ isCollapsed, handleToggle, handleTabClick, activeTab }) {
  const user = useSelector(userSelector);
  const [lang] = useState('FR');
  const dispatch = useDispatch();
  const buttonClasses = 'flex items-center p-4 focus:outline-none transition-colors duration-200';

  const tabs = user.role_id === 1
    ? [
      { name: 'dashboard', icon: <DashboardOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.dashboard },
      { name: 'clients', icon: <GroupOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.clients },
      { name: 'payments', icon: <CreditCardOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.payments },

      // Settings Section Title (non-clickable)
      { name: 'settings', icon: <SettingsOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.settings, isHeader: true },

      // Settings items
      { name: 'domiciliation-centers', icon: <LocationOnOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.domiciliationCenters },
      { name: 'status', icon: <MessageOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.status },
      { name: 'managers', icon: <ManageAccountsOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.managers },
      { name: 'packs', icon: <CategoryOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.packs }, // Updated icon
      { name: 'pack-categories', icon: <ViewListOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.packCategories }, // Updated icon
      { name: 'profile', icon: <AccountBoxOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.profile },
    ]
    : [
      { name: 'dashboard', icon: <DashboardOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.dashboard },
      { name: 'clients', icon: <GroupOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.clients },
      { name: 'payments', icon: <CreditCardOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.payments },

      { name: 'settings', icon: <SettingsOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.settings, isHeader: true },

      // Settings items
      { name: 'domiciliation-centers', icon: <LocationOnOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.domiciliationCenters },
      { name: 'status', icon: <MessageOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.status },
      { name: 'pack-categories', icon: <ViewListOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.packCategories },
      { name: 'profile', icon: <AccountBoxOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.profile },
    ];

  return (
    <>
      <div className={`hidden md:flex flex-col justify-between ${isCollapsed ? 'w-24' : 'w-64'} h-screen text-black transition-width duration-300 bg-white overflow-hidden shadow-md`}>
        <button style={{ paddingTop: '5px', paddingBottom: '20px' }} onClick={handleToggle} className=" focus:outline-none">
          {isCollapsed
            ? (
              <>
                <hr className="border-t border-gray-300" />
                <div className='flex  justify-center items-center shadow-md w-full gap-4 p-4 bg-white shadow-md rounded-xl '>
                  <div className=' rounded-xl'>
                    <MenuOutlinedIcon fontSize='small' />
                  </div>
                </div>
              </>
            )
            : (
              <>
                <hr className="border-t border-gray-300" />
                <div className='flex  justify-center items-center shadow-md w-full gap-4 p-4 bg-white shadow-md rounded-xl '>
                  <span><img src={logo} alt="" /></span>
                  <CloseOutlinedIcon fontSize='small' />
                </div>
              </>

            )
          }
        </button>
        <nav className="flex-1 overflow-y-auto">
          {tabs.map((tab) => (
            <React.Fragment key={tab.name}>
              {tab.isHeader ? (
                <div className="flex items-center p-4" style={{ backgroundColor: '#F7F7F7', color: '#6B7280', borderTop: '2px solid #E0E0E0' }}>
                  {/* Styling for Settings tab */}
                  <SettingsOutlinedIcon fontSize="small" />
                  {!isCollapsed && <span className="ml-2" style={{ fontWeight: '600' }}>{tab.label}</span>}
                </div>
              ) : (
                <button
                  onClick={() => handleTabClick(tab.name)}
                  className={`${buttonClasses} ${activeTab === tab.name ? 'bg-gray-200' : ''}`}
                >
                  <div className="flex items-center">
                    {tab.icon}
                    {!isCollapsed && <span className="ml-2">{tab.label}</span>}
                  </div>
                </button>
              )}
            </React.Fragment>
          ))}

        </nav>
        <hr className="border-t border-gray-300" />

        <div className='flex flex-col justify-center rounded-full shadow-md mb-10 items-center w-full'>
          <button
            onClick={() => {
              const confirmLogout = window.confirm(getLang(lang).sideBar.confirmLogout);
              if (confirmLogout) {
                dispatch(setUser(null));
              }
            }}
            className='flex items-center  space-x-2 p-2  transition-colors'
            style={{
              color: '#FF0000', // Red color for the logout text and icon
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = '#CC0000')} // Darker red on hover
            onMouseLeave={(e) => (e.currentTarget.style.color = '#FF0000')} // Original red on mouse leave
          >
            <LogoutOutlinedIcon fontSize='small' />
            {!isCollapsed && <span>{getLang(lang).sideBar.logout}</span>}
          </button>
        </div>

      </div>
      <BottomBar activeTab={activeTab} handleTabClick={handleTabClick} />
    </>
  );
}

export default AdminSidebar;
