/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import * as material from '@mui/material';
import { Link } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';

import ReactDOMServer from 'react-dom/server';

import ClearIcon from '@mui/icons-material/Clear';
import UploadIcon from "@mui/icons-material/Upload";

import getEnvironement from '../../environnement';

import GenerateAttestationPDF from './pdf/generateAttestationPDF';
import GenerateContratPDF from './pdf/generateContratPDF';
import StatutesPDF from './pdf/generateStatusPDF';
import GenerateImmatriculationRcPDF from './pdf/generateImmatreculationRcPDF';

import html2pdf from "html2pdf.js";
import { FormatMoneyDH } from '../helpers';

function ProductData({
  label,
  data
}) {
  return (
    <div className='flex'>
      <b className='mr-3'>{label}</b>
      <p>{data}</p>
    </div>
  );
}




const Tables = ({ soussignes, gerents, objetsSocial, apports, partsSocial, denominations }) => {
  const soussignesData = soussignes.map(parseJsonData);
  const objetsSocialData = objetsSocial.map(parseJsonData);
  const apportsData = apports.map(parseJsonData);
  const partsSocialData = partsSocial.map(parseJsonData);
  const denominationsData = denominations.map(parseJsonData);
  const gerentsData = gerents.map(parseJsonData);

  console.log('gerents', gerentsData);

  return (
    <div style={{ width: '70vw' }}>
      <h3 className='mb-2   font-bold text-[15px]'>Soussignés</h3>
      <material.TableContainer component={material.Paper} sx={{
        width: '70vw',
        overflowX: 'auto',
      }}>        <material.Table>
          <material.TableHead>
            <material.TableRow>
              <material.TableCell>Nom</material.TableCell>
              <material.TableCell>Email</material.TableCell>
              <material.TableCell>GSM</material.TableCell>
              <material.TableCell>Ville de Résidence</material.TableCell>
              <material.TableCell>Adresse</material.TableCell>
            </material.TableRow>
          </material.TableHead>
          <material.TableBody>
            {soussignesData.map((item, index) => (
              <material.TableRow key={index}>
                <material.TableCell>{item.data?.nom}</material.TableCell>
                <material.TableCell>{item.data?.email}</material.TableCell>
                <material.TableCell>{item.data?.gsm}</material.TableCell>
                <material.TableCell>{item.data?.villeResidence}</material.TableCell>
                <material.TableCell>{item.data?.adresse}</material.TableCell>
              </material.TableRow>
            ))}
          </material.TableBody>
        </material.Table>
      </material.TableContainer>

      <h3 className='mb-2 mt-10  font-bold text-[15px]'>Gerents</h3>

      <material.TableContainer component={material.Paper} sx={{
        width: '70vw',
        overflowX: 'auto',
      }}>        <material.Table>
          <material.TableHead>
            <material.TableRow>
              <material.TableCell>Nom</material.TableCell>
              <material.TableCell>Prénom</material.TableCell>
              <material.TableCell>Nationalité</material.TableCell>
              <material.TableCell>Date de Naissance</material.TableCell>
              <material.TableCell>Adresse</material.TableCell>
              <material.TableCell>CIN</material.TableCell>
            </material.TableRow>
          </material.TableHead>
          <material.TableBody>
            {gerentsData.map((item, index) => {
              if (!item.autre) {
                // Display soussigne's data
                return (
                  <material.TableRow key={index}>
                    <material.TableCell>{item.soussigne?.data?.nom || "N/A"}</material.TableCell>
                    <material.TableCell>{item.soussigne?.data?.prenom || "N/A"}</material.TableCell>
                    <material.TableCell>{item.soussigne?.data?.nationalite || "N/A"}</material.TableCell>
                    <material.TableCell>{item.soussigne?.data?.dateBirth || "N/A"}</material.TableCell>
                    <material.TableCell>{item.soussigne?.data?.adresse || "N/A"}</material.TableCell>
                    <material.TableCell>{item.soussigne?.data?.cin || "N/A"}</material.TableCell>
                  </material.TableRow>
                );
              } else {
                // Display item's own data for "autre: true"
                return (
                  <material.TableRow key={index}>
                    <material.TableCell>{item.nom || "N/A"}</material.TableCell>
                    <material.TableCell>{item.prenom || "N/A"}</material.TableCell>
                    <material.TableCell>{item.nationalite || "N/A"}</material.TableCell>
                    <material.TableCell>{item.date_birth || "N/A"}</material.TableCell>
                    <material.TableCell>{item.address || "N/A"}</material.TableCell>
                    <material.TableCell>{item.cin || "N/A"}</material.TableCell>
                  </material.TableRow>
                );
              }
            })}
          </material.TableBody>
        </material.Table>
      </material.TableContainer>


      <h3 className='mb-2 mt-10  font-bold text-[15px]'>Objets Social</h3>
      <material.TableContainer component={material.Paper} sx={{
        width: '70vw',
        overflowX: 'auto',
      }}>        <material.Table>
          <material.TableHead>
            <material.TableRow>
              <material.TableCell>Objet Social</material.TableCell>
            </material.TableRow>
          </material.TableHead>
          <material.TableBody>
            {objetsSocialData.map((item, index) => (
              <material.TableRow key={index}>
                <material.TableCell>{item.objetSocial}</material.TableCell>
              </material.TableRow>
            ))}
          </material.TableBody>
        </material.Table>
      </material.TableContainer>

      <h3 className='mb-2 mt-10 font-bold text-[15px]'>Apports</h3>
      <material.TableContainer component={material.Paper} sx={{
        width: '70vw',
        overflowX: 'auto',
      }}>        <material.Table>
          <material.TableHead>
            <material.TableRow>
              <material.TableCell>Soussigne</material.TableCell>
              <material.TableCell>Apport</material.TableCell>
              <material.TableCell>Autre Apport</material.TableCell>
            </material.TableRow>
          </material.TableHead>
          <material.TableBody>
            {apportsData.map((item, index) => (
              <material.TableRow key={index}>
                <material.TableCell>{item.soussigne}</material.TableCell>
                <material.TableCell>{item.apport} %</material.TableCell>
                <material.TableCell>{item.autreApport}</material.TableCell>
              </material.TableRow>
            ))}
          </material.TableBody>
        </material.Table>
      </material.TableContainer>

      <h3 className='mb-2 mt-10  font-bold text-[15px]'>Parts Sociales</h3>
      <material.TableContainer component={material.Paper} sx={{
        width: '70vw',
        overflowX: 'auto',
      }}>        <material.Table>
          <material.TableHead>
            <material.TableRow>
              <material.TableCell>Soussigne</material.TableCell>
              <material.TableCell>Part Sociale</material.TableCell>
            </material.TableRow>
          </material.TableHead>
          <material.TableBody>
            {partsSocialData.map((item, index) => (
              <material.TableRow key={index}>
                <material.TableCell>{item.soussigne}</material.TableCell>
                <material.TableCell>{FormatMoneyDH(item.partSocial)}</material.TableCell>
              </material.TableRow>
            ))}
          </material.TableBody>
        </material.Table>
      </material.TableContainer>

      <h3 className='mb-2 mt-10'>Dénominations</h3>
      <material.TableContainer component={material.Paper} sx={{
        width: '70vw',
        overflowX: 'auto',
      }}>        <material.Table>
          <material.TableHead>
            <material.TableRow>
              <material.TableCell>Dénomination</material.TableCell>
              <material.TableCell>Sigle</material.TableCell>
            </material.TableRow>
          </material.TableHead>
          <material.TableBody>
            {denominationsData.map((item, index) => (
              <material.TableRow key={index}>
                <material.TableCell>{item.name}</material.TableCell>
                <material.TableCell>{item.sigle}</material.TableCell>
              </material.TableRow>
            ))}
          </material.TableBody>
        </material.Table>
      </material.TableContainer>
    </div>
  );
};

// Utility function to parse raw data
const parseJsonData = (data) => {
  try {
    // Check if the data is already an object (if it's an object, no need to parse)
    if (typeof data === 'string') {
      return JSON.parse(data);
    }
    return data; // If it's already an object, return it as-is
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return {}; // Return an empty object in case of error
  }
};


const activities = [
  { value: 'A', label: 'AGRICULTURE, SYLVICULTURE ET PÊCHE' },
  { value: 'B', label: 'INDUSTRIES EXTRACTIVES' },
  { value: 'C', label: 'INDUSTRIE MANUFACTURIÈRE' },
  { value: 'D', label: 'PRODUCTION ET DISTRIBUTION D\'ÉLECTRICITÉ, DE GAZ, DE VAPEUR ET D\'AIR CONDITIONNÉ' },
  { value: 'E', label: 'PRODUCTION ET DISTRIBUTION D\'EAU ; ASSAINISSEMENT, GESTION DES DÉCHETS ET DÉPOLLUTION' },
  { value: 'F', label: 'CONSTRUCTION' },
  { value: 'G', label: 'COMMERCE ; RÉPARATION D\'AUTOMOBILES ET DE MOTOCYCLES' },
  { value: 'H', label: 'TRANSPORTS ET ENTREPOSAGE' },
  { value: 'I', label: 'HÉBERGEMENT ET RESTAURATION' },
  { value: 'J', label: 'INFORMATION ET COMMUNICATION' },
  { value: 'K', label: 'ACTIVITÉS FINANCIÈRES ET D\'ASSURANCE' },
  { value: 'L', label: 'ACTIVITÉS IMMOBILIÈRES' },
  { value: 'M', label: 'ACTIVITÉS SPÉCIALISÉES, SCIENTIFIQUES ET TECHNIQUES' },
  { value: 'N', label: 'ACTIVITÉS DE SERVICES ADMINISTRATIFS ET DE SOUTIEN' },
  { value: 'O', label: 'ADMINISTRATION PUBLIQUE' },
  { value: 'P', label: 'ENSEIGNEMENT' },
  { value: 'Q', label: 'SANTÉ HUMAINE ET ACTION SOCIALE' },
  { value: 'R', label: 'ARTS, SPECTACLES ET ACTIVITÉS RÉCRÉATIVES' },
  { value: 'S', label: 'AUTRES ACTIVITÉS DE SERVICES' },
  { value: 'T', label: 'ACTIVITÉS DES MÉNAGES EN TANT QU\'EMPLOYEURS ; ACTIVITÉS INDIFFÉRENCIÉES DES MÉNAGES EN TANT QUE PRODUCTEURS DE BIENS ET SERVICES POUR USAGE PROPRE' },
  { value: 'U', label: 'ACTIVITÉS EXTRA-TERRITORIALES' },
];

const parseRawData = (rawData) => {
  try {
    const parsedData = JSON.parse(rawData);
    if (Array.isArray(parsedData)) {
      return parsedData.map(item => JSON.parse(item));
    }
    return parsedData;
  } catch (error) {
    console.error("Error parsing data", error);
    return [];
  }
};


export function CreationEntreprise({
  produit,
  onClickSaveDocument,
  onClickDeleteDocument,
  saveEnterpriseDetails

}) {

  const soussignesData = parseRawData(produit?.soussignes || '[]');
  const objetsSocialData = parseRawData(produit?.objetsSocial || '[]');
  const apportsData = parseRawData(produit?.apports || '[]');
  const partsSocialData = parseRawData(produit?.partsSocial || '[]');
  const denominationsData = parseRawData(produit?.denominations || '[]');
  const gerentsData = parseRawData(produit?.gerents || '[]');


  return (
    <div className="shadow-md rounded px-10  pb-5 pt-5">
      {console.log(produit)}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>

          <h3 className="text-md font-bold mb-2">Bénéficiaire</h3>
          <ProductData label="Type bénéficiaire: " data={produit?.typeBeneficiaire} />
          {produit?.nomBeneficiaire && <ProductData label="Nom bénéficiaire: " data={produit.nomBeneficiaire} />}
          {produit?.prenomBeneficiaire && <ProductData label="Prénom bénéficiaire: " data={produit.prenomBeneficiaire} />}
          {produit?.nationaliteBeneficiaire && <ProductData label="Nationalité bénéficiaire: " data={produit.nationaliteBeneficiaire} />}
          {produit?.gsmBeneficiare && <ProductData label="GSM bénéficiaire: " data={produit.gsmBeneficiare} />}
          {produit?.emailBeneficiaire && <ProductData label="Email bénéficiaire: " data={produit.emailBeneficiaire} />}
          {produit?.cinBeneficiaire && <ProductData label="CIN bénéficiaire: " data={produit.cinBeneficiaire} />}
          {produit?.adresseBeneficiaire && <ProductData label="Adresse bénéficiaire: " data={produit.adresseBeneficiaire} />}
          {produit?.denominationBeneficiaire && <ProductData label="Dénomination bénéficiaire: " data={produit.denominationBeneficiaire} />}
          {produit?.iceBeneficiaire && <ProductData label="ICE bénéficiaire: " data={produit.iceBeneficiaire} />}
          {produit?.rcBeneficiaire && <ProductData label="RC bénéficiaire: " data={produit.rcBeneficiaire} />}
        </div>
        <hr />
        <div className="mt-6">
          <h3 className="text-md font-bold mb-2">Informations Générales</h3>
          <ProductData label="Forme juridique: " data={produit?.formeJuridique} />
          <ProductData label="Ville: " data={produit?.ville} />
          <ProductData label="Activité commerciale: " data={
            activities.find(activity => activity.value === produit?.activiteCommercial)?.label || 'Non spécifiée'
          } />
          <ProductData label="Siège social: " data={
            produit?.siege_social
              ? produit.siege_social.address
              : `${produit?.customSiegeSocial}, ${produit?.customVilleTribunal}`
          } />
          <ProductData label="Durée de la société: " data={produit?.dureeSociete} />
          <ProductData label="Capital social: " data={FormatMoneyDH(produit?.capitalSocial)} />
          <ProductData label="Date de création: " data={produit?.created_at} />
          <ProductData label="Date de dernière modification: " data={produit?.updated_at} />
        </div>
        <hr />
        <div className="mt-6">
          <Tables soussignes={soussignesData} gerents={gerentsData} objetsSocial={objetsSocialData} apports={apportsData} partsSocial={partsSocialData} denominations={denominationsData} />
        </div>
      </div>

      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />



      {produit?.documents?.some(doc => doc.file.endsWith("- Attestation ICE")) &&
        produit?.documents?.some(doc => doc.file.endsWith("- Certificat Négatif")) && (
          <UpdateEnterpriseDetailsWithUpload
            ICE={produit?.ice}
            DenominationValide={produit?.denominationValide}
            denominations={denominationsData}
            SigleValide={produit?.sigleValide}
            onSaveDetails={(data) => saveEnterpriseDetails(produit.id, data)}
            onClickSaveDocument={onClickSaveDocument}
            productData={produit}
            soussignesData={soussignesData}
            gerentsData={gerentsData}
            apportsData={apportsData}
            partsSocialData={partsSocialData}
            objetsSocialData={objetsSocialData}
          />
        )}




    </div>
  );
}

function ProductDocuments({ documents, onClickDeleteDocument, onClickSaveDocument }) {
  const [fileName, setFileName] = useState("Ajouter un document");
  const [fileType, setFileType] = useState("reçu");
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentCategory, setDocumentCategory] = useState("Attestation ICE");
  const [deletingDocumentId, setDeletingDocumentId] = useState(null);
  const [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] = useState(false);
  const [deleteAllType, setDeleteAllType] = useState("");
  const [isDeletingAll, setIsDeletingAll] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setSelectedFile(file);
    } else {
      setFileName("Ajouter un document");
      setSelectedFile(null);
    }
  };

  const handleDelete = async (id) => {
    setDeletingDocumentId(id);
    await onClickDeleteDocument(id);
    setDeletingDocumentId(null);
  };

  const handleDeleteAll = async () => {
    setIsDeletingAll(true);
    const documentsToDelete = documents.filter((doc) => doc.type === deleteAllType);
    for (const doc of documentsToDelete) {
      await onClickDeleteDocument(doc.id);
    }
    setIsDeletingAll(false);
    setIsDeleteAllDialogOpen(false);
  };

  const renderDocumentsTable = (title, filterType) => {
    const filteredDocuments = documents?.filter((doc) => doc.type === filterType);

    if (filteredDocuments?.length === 0) return null;

    return (
      <div className='mt-8'>
        <material.Typography variant="h6" className="text-md  font-bold mb-4">
          {title}
        </material.Typography>
        <material.TableContainer component={material.Paper} sx={{ width: "70vw", overflowX: "auto" }}>
          <material.Table>
            <material.TableHead>
              <material.TableRow>
                <material.TableCell align="left" className="font-semibold text-gray-700">
                  Nom du document
                </material.TableCell>
                <material.TableCell align="left" className="font-semibold text-gray-700">
                  Type
                </material.TableCell>
                <material.TableCell align="center" className="font-semibold text-gray-700">
                  Actions
                </material.TableCell>
              </material.TableRow>
            </material.TableHead>
            <material.TableBody>
              {filteredDocuments?.map((document, index) => (
                <material.TableRow key={index}>
                  <material.TableCell>
                    <Link
                      className="text-blue-600 hover:underline"
                      to={`${getEnvironement().BACKEND_URL}/storage/app/public/pdfs/${document.file}`}
                      target="_blank"
                    >
                      {document.file}
                    </Link>
                  </material.TableCell>
                  <material.TableCell>{document.type}</material.TableCell>
                  <material.TableCell align="center">
                    <material.Tooltip title="Supprimer">
                      <material.IconButton
                        onClick={() => handleDelete(document.id)}
                        color="error"
                        disabled={deletingDocumentId === document.id}
                      >
                        {deletingDocumentId === document.id ? (
                          <material.CircularProgress size={24} color="error" />
                        ) : (
                          <ClearIcon />
                        )}
                      </material.IconButton>
                    </material.Tooltip>
                  </material.TableCell>
                </material.TableRow>
              ))}
            </material.TableBody>
          </material.Table>
        </material.TableContainer>
        <div className="mt-0 flex justify-end w-full">
          <material.Button
            variant="outlined"
            color="error"
            onClick={() => {
              setDeleteAllType(filterType);
              setIsDeleteAllDialogOpen(true);
            }}
            disabled={isDeletingAll}
          >
            {isDeletingAll && deleteAllType === filterType ? (
              <material.CircularProgress size={24} color="error" />
            ) : (
              "Supprimer tout"
            )}
          </material.Button>
        </div>

      </div>
    );
  };

  return (
    <div className="p-4 bg-gray-50 rounded-lg shadow-md mt-10">
      {/* Upload Section */}
      <material.Typography variant="subtitle1" className="mb-5 font-semibold text-gray-700">
        Ajouter un Document
      </material.Typography>
      <div className="flex flex-wrap items-center gap-4 mb-10">
        <material.Button
          variant="outlined"
          startIcon={<UploadIcon />}
          style={{
            height: "40px",
            padding: "0 16px",
            fontSize: "14px",
            color: "#374151",
            borderColor: "#D1D5DB",
          }}
        >
          {fileName}
          <input
            type="file"
            onChange={handleFileChange}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              cursor: "pointer",
              opacity: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </material.Button>
        <material.Select
          value={fileType}
          onChange={(e) => setFileType(e.target.value)}
          displayEmpty
          variant="outlined"
          style={{
            minWidth: "150px",
            height: "40px",
            fontSize: "14px",
            backgroundColor: "white",
            borderColor: "#D1D5DB",
          }}
        >
          <material.MenuItem value="reçu">Reçu</material.MenuItem>
          <material.MenuItem value="légaliser">À Légaliser</material.MenuItem>
          <material.MenuItem value="légalisé">Légalisé</material.MenuItem>
        </material.Select>
        {fileType === "reçu" && (
          <material.Select
            value={documentCategory}
            onChange={(e) => setDocumentCategory(e.target.value)}
            displayEmpty
            variant="outlined"
            style={{
              minWidth: "180px",
              height: "40px",
              fontSize: "14px",
              backgroundColor: "white",
              borderColor: "#D1D5DB",
            }}
          >
            <material.MenuItem value="Attestation ICE">Attestation ICE</material.MenuItem>
            <material.MenuItem value="Certificat Négatif">Certificat Négatif</material.MenuItem>
          </material.Select>
        )}
        <material.Button
          variant="contained"
          style={{
            height: "40px",
            backgroundColor: "#2563EB",
            color: "white",
            fontSize: "14px",
            padding: "0 16px",
          }}
          onClick={() =>
            onClickSaveDocument(
              selectedFile,
              fileType === "reçu" ? `${fileName} - ${documentCategory}` : fileName,
              fileType,
              setSelectedFile,
              setFileName
            )
          }
        >
          Enregistrer
        </material.Button>
      </div>

      {/* Documents Tables */}
      {renderDocumentsTable("Documents Reçu", "reçu")}
      {renderDocumentsTable("Documents à Légaliser", "légaliser")}
      {renderDocumentsTable("Documents Légalisés", "légalisé")}

      {/* Delete All Confirmation Dialog */}
      <material.Dialog
        open={isDeleteAllDialogOpen}
        onClose={() => setIsDeleteAllDialogOpen(false)}
        aria-labelledby="delete-all-dialog-title"
      >
        <material.DialogTitle id="delete-all-dialog-title">Confirmer la suppression</material.DialogTitle>
        <material.DialogContent>
          <material.DialogContentText>
            Êtes-vous sûr de vouloir supprimer tous les documents de type "{deleteAllType}" ?
          </material.DialogContentText>
        </material.DialogContent>
        <material.DialogActions>
          <material.Button onClick={() => setIsDeleteAllDialogOpen(false)} color="primary">
            Annuler
          </material.Button>
          <material.Button onClick={handleDeleteAll} color="error" disabled={isDeletingAll}>
            {isDeletingAll ? <material.CircularProgress size={24} color="error" /> : "Supprimer"}
          </material.Button>
        </material.DialogActions>
      </material.Dialog>
    </div>
  );
}










function UpdateEnterpriseDetailsWithUpload({
  ICE,
  denominations,
  DenominationValide,
  SigleValide,
  onSaveDetails,
  onClickSaveDocument,
  productData,
  gerentsData,
  soussignesData,
  apportsData,
  partsSocialData,
  objetsSocialData,
}) {
  const [ice, setICE] = useState(ICE || "");
  const [denomination, setDenomination] = useState(DenominationValide || "");
  const [customDenomination, setCustomDenomination] = useState(DenominationValide || "");
  const [sigle, setSigle] = useState(SigleValide || "");
  const [customSigle, setCustomSigle] = useState(SigleValide || "");
  const [isCustom, setIsCustom] = useState(false);
  const [isSigleCustom, setIsSigleCustom] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allDocumentsDeleted, setAllDocumentsDeleted] = useState(false);

  useEffect(() => {
    const validDenomination = denominations.some((item) => item.name === DenominationValide);
    setIsCustom(!validDenomination);

    const validSigle = denominations.some((item) => item.sigle === SigleValide);
    setIsSigleCustom(!validSigle);

    const aLegaliserDocuments = productData?.documents.filter((doc) => doc.type === "légaliser");
    setAllDocumentsDeleted(aLegaliserDocuments?.length === 0);
  }, [DenominationValide, SigleValide, denominations, productData]);

  const generateAndUploadPDF = async () => {
    const generatePDF = async (component, fileName, format = "a4") => {
      const input = document.createElement("div");
      const pdfContent = ReactDOMServer.renderToString(
        React.createElement(component, {
          productData,
          soussignesData,
          gerentsData,
          apportsData,
          partsSocialData,
          objetsSocialData,
        })
      );
      input.innerHTML = pdfContent;
      document.body.appendChild(input);

      const style = document.createElement("style");
      style.textContent = `
        .page-break {
          display: block;
          height: 1cm;
          page-break-before: always;
        }
      `;
      document.head.appendChild(style);

      try {
        const pageSizes = {
          a4: [21, 29.7],
          a3: [29.7, 42],
        };

        const options = {
          filename: fileName,
          image: { type: "jpeg", quality: 0.77 },
          html2canvas: { scale: 2, useCORS: true, logging: false },
          jsPDF: {
            unit: "cm",
            format: pageSizes[format] || pageSizes.a4,
            orientation: "portrait",
            compress: true,
          },
          pagebreak: { mode: ["avoid-all", "css", "legacy"], before: ".page-break" },
        };

        const pdfBlob = await html2pdf().from(input).set(options).outputPdf("blob");
        return new File([pdfBlob], fileName, { type: "application/pdf" });
      } catch (error) {
        console.error(`Error generating PDF (${fileName}):`, error);
        throw error;
      } finally {
        document.body.removeChild(input);
        document.head.removeChild(style);
      }
    };

    const tasks = [
      { component: GenerateAttestationPDF, fileName: "_Attestation_Domiciliation.pdf" },
      { component: GenerateContratPDF, fileName: "_Contrat_Domiciliation.pdf" },
      { component: StatutesPDF, fileName: `_${productData?.denominationValide}_status.pdf` },
      {
        component: GenerateImmatriculationRcPDF,
        fileName: `_${productData?.denominationValide}_immatriculation_RC.pdf`,
        format: "a3",
      },
    ];

    try {
      const pdfFiles = await Promise.all(
        tasks.map((task) => generatePDF(task.component, task.fileName, task.format).catch(() => null))
      );
      const validPdfFiles = pdfFiles.filter((file) => file);

      for (const pdfFile of validPdfFiles) {
        try {
          if (typeof onClickSaveDocument === "function") {
            await onClickSaveDocument(pdfFile, pdfFile.name, "légaliser");
          }
        } catch (error) {
          console.error(`Error uploading PDF (${pdfFile.name}):`, error.response?.data || error.message);
        }
      }
      console.log("All PDFs generated and uploaded successfully.");
    } catch (error) {
      console.error("Error generating or uploading PDFs:", error);
    }
  };

  const handleSave = async () => {
    const finalDenomination = isCustom ? customDenomination : denomination;
    const finalSigle = isSigleCustom ? customSigle : sigle;

    if (!finalDenomination || !finalSigle || !ice) {
      toast.error("Tous les champs sont requis", { position: "top-right", transition: Bounce });
      return;
    }

    if (!allDocumentsDeleted) {
      toast.error("Veuillez supprimer tous les documents 'A Légaliser' avant de sauvegarder.", {
        position: "top-right",
        transition: Bounce,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      await onSaveDetails({ ice, denomination: finalDenomination, sigle: finalSigle });
      await generateAndUploadPDF();

      toast.success("Détails sauvegardés avec succès!", { position: "top-right", transition: Bounce });
    } catch (error) {
      console.error("Error saving details:", error);
      toast.error("Une erreur s'est produite.", { position: "top-right", transition: Bounce });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "100%", margin: "auto" }}>
      {isSubmitting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <material.CircularProgress color="primary" />
        </div>
      )}

      <material.Typography variant="h5" gutterBottom>
        Mettre à jour les Détails
      </material.Typography>

      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <material.TextField
          select
          label="Dénomination"
          value={isCustom ? "Autre" : denomination}
          onChange={(e) => {
            const value = e.target.value;
            setIsCustom(value === "Autre");
            setDenomination(value === "Autre" ? "" : value);
          }}
          fullWidth
          margin="normal"
          variant="outlined"
        >
          {denominations.map((item) => (
            <material.MenuItem key={item.name} value={item.name}>
              {item.name}
            </material.MenuItem>
          ))}
          <material.MenuItem value="Autre">Autre</material.MenuItem>
        </material.TextField>

        {isCustom && (
          <material.TextField
            label="Autre Dénomination"
            value={customDenomination}
            onChange={(e) => setCustomDenomination(e.target.value)}
            fullWidth
            variant="outlined"
          />
        )}

        <material.TextField
          select
          label="Sigle"
          value={isSigleCustom ? "Autre" : sigle}
          onChange={(e) => {
            const value = e.target.value;
            setIsSigleCustom(value === "Autre");
            setSigle(value === "Autre" ? "" : value);
          }}
          fullWidth
          margin="normal"
          variant="outlined"
        >
          {denominations
            .filter((item) => item.sigle !== "")
            .map((item) => (
              <material.MenuItem key={item.sigle} value={item.sigle}>
                {item.sigle}
              </material.MenuItem>
            ))}
          <material.MenuItem value="Autre">Autre</material.MenuItem>
        </material.TextField>

        {isSigleCustom && (
          <material.TextField
            label="Autre Sigle"
            value={customSigle}
            onChange={(e) => setCustomSigle(e.target.value)}
            fullWidth
            variant="outlined"
          />
        )}

        <material.TextField
          label="ICE"
          value={ice}
          onChange={(e) => setICE(e.target.value)}
          fullWidth
          variant="outlined"
        />

        {!allDocumentsDeleted && (
          <material.Alert severity="error" variant="outlined" style={{ marginTop: "10px" }}>
            Veuillez supprimer tous les documents "À Légaliser" avant de sauvegarder.
          </material.Alert>
        )}
        <div className="flex justify-center w-full">

          <material.Button
            variant="contained"
            onClick={handleSave}
            disabled={isSubmitting || !allDocumentsDeleted}
            style={{ marginTop: "20px", width: "50%" }}
          >
            {isSubmitting ? "Enregistrement en cours..." : "Sauvegarder et Générer les Documents À Légaliser"}
          </material.Button>
        </div>
      </div>
    </div>
  );
}








/* function ProductStatus({
  status,
  idProduit,
  onClickSaveStatus,
  onStatusUpdate,
}) {
  const user = useSelector(userSelector);
  const [statuses, setStatuses] = useState([{ id: 'default', status: 'Sélectionnez un statut' }]);
  const [selectedStatus, setSelectedStatus] = useState('Sélectionnez un statut');
  const [selectedIndex, setSelectedIndex] = useState('default');
  const [currentStatus, setCurrentStatus] = useState(status);

  const getStatuses = async () => {
    const params = { managerId: user.id };
    try {
      const response = await axios.get(`${getEnvironement().API_URL}/admin/status`, { params });
      setStatuses([{ id: 'default', status: 'Sélectionnez un statut' }, ...response.data]);
    } catch (error) {
      console.error('Error fetching statuses:', error);
    }
  };

  const getStatus = (statusString) => {
    if (statusString?.status === 'Rejetée') {
      return (
        <p className='rounded-full bg-[#FDB9BC] font-poppins font-normal text-sm/[16px] text-center py-1 px-5 m-1 text-[#F04148]'>
          Rejetée
        </p>
      );
    } else if (statusString?.status === 'Validée') {
      return (
        <p className='rounded-full bg-[#CAFFBD] font-poppins font-normal text-sm/[16px] text-center py-1 px-5 m-1 text-[#35DE0A]'>
          Validée
        </p>
      );
    } else {
      return (
        <p className='rounded-full bg-[#E5E5E5] font-poppins font-normal text-sm/[16px] text-center py-1 px-5 m-1 text-[#000000]'>
          {statusString?.status}
        </p>
      );
    }
  };

  const handleSaveStatus = async () => {
    try {
      await onClickSaveStatus(selectedIndex, idProduit);
      const newStatus = statuses.find((item) => item.id === selectedIndex);
      setCurrentStatus(newStatus);
      if (onStatusUpdate) onStatusUpdate(newStatus);
    } catch (error) {
      console.error('Error saving status:', error);
    }
  };

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  return (
    <div className="update-section" style={{ padding: '20px', maxWidth: '100%', margin: 'auto' }}>
      <hr className='mb-5' />

      <Typography variant="h5" gutterBottom align="left" style={{ fontWeight: 'bold', color: '#333' }}>
        Mettre à jour le statut du produit
      </Typography>

      <div className="status-list">
        <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>Statuts actuels:</p>
        {currentStatus && (
          <div key={currentStatus.id} className="flex items-center" style={{ marginBottom: '10px' }}>
            {getStatus(currentStatus)}
          </div>
        )}
      </div>

      <div className="flex" style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <div style={{ marginBottom: '10px' }}>
          <Select
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              const selected = statuses.find((item) => item.status === e.target.value);
              setSelectedIndex(selected?.id || 'default');
            }}
            fullWidth
            variant="outlined"
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '16px',
              fontWeight: 300,
              color: '#242424',
              borderRadius: '10px',
              borderColor: '#ccc',
              padding: '5px 12px',
            }}
          >
            {statuses.map((item) => (
              <MenuItem key={item.id} value={item.status}>
                {item.status}
              </MenuItem>
            ))}
          </Select>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveStatus}
          fullWidth
          sx={{
            padding: '12px 24px',
            fontWeight: 'bold',
            backgroundColor: '#1976d2',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#1565c0',
            },
          }}
        >
          Enregistrer le statut
        </Button>
      </div>
    </div>
  );
} */




export function CreationStatut({

  produit,

  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>

      <div className="bg-white shadow-md rounded px-4 py-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <ProductData label='Dénomination: ' data={produit?.denomination} />
          <ProductData label='Sigle: ' data={produit?.sigle} />
          <ProductData label='Forme juridique: ' data={produit?.formeJuridique} />
          <ProductData label='Ville: ' data={produit?.ville} />
          <ProductData label='Activité commercial: ' data={produit?.activiteCommercial} />
          <ProductData label='Soussignés: ' data={produit?.soussignes && JSON.parse(produit?.soussignes)} />
          <ProductData label='Siège social: ' data={
            produit?.siege_social
              ? produit.siege_social.address
              : `${produit?.customSiegeSocial}, ${produit?.customVilleTribunal}`
          } />
          <ProductData label='Durée de la societe: ' data={produit?.dureeSociete} />
          <ProductData label='Objets social: ' data={produit?.objetsSocial && JSON.parse(produit?.objetsSocial)} />
          <ProductData label='Apports: ' data={produit?.apports && JSON.parse(produit?.apports)} />
          <ProductData label='Parts social: ' data={produit?.partsSocial && JSON.parse(produit?.partsSocial)} />
          <ProductData label='date de création: ' data={produit?.created_at} />
          <ProductData label='date de dérnère modification:' data={produit?.updated_at} />
        </div>
      </div>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />

    </div>
  );
}


export function CreationAssociation({

  produit,

  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination} />
      <ProductData label='Abréviation: ' data={produit?.abreviation} />
      <ProductData label='Objets social: ' data={produit?.objetsSocial && JSON.parse(produit?.objetsSocial)} />
      <ProductData label='Siège social: ' data={
        produit?.siege_social
          ? produit.siege_social.address
          : `${produit?.customSiegeSocial}, ${produit?.customVilleTribunal}`
      } />
      <ProductData label='Membres de la fondateurs: ' data={produit?.MembresFondateurs && JSON.parse(produit?.MembresFondateurs)} />
      <br />  <hr /> <br />


      <ProductData label='date de création: ' data={produit?.created_at} />
      <ProductData label='date de dérnère modification:' data={produit?.updated_at} />
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />

    </div>
  );
}

export function CreationAutoEntreprise({

  produit,

  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>

      <ProductData label='type micro-entreprise: ' data={produit?.typeMicroEntreprise} />
      <ProductData label='délai de création entreprise: ' data={produit?.delaiCreationEntreprise} />
      <ProductData label='Déjà auto-entrepreneur: ' data={produit?.isDejaAutoEntrepreneur} />
      <ProductData label='Siège social: ' data={
        produit?.siege_social
          ? produit.siege_social.address
          : `${produit?.customSiegeSocial}, ${produit?.customVilleTribunal}`
      } />
      <ProductData label={'Domaine d\'activité: '} data={produit?.domaineActivite} />
      <ProductData label='Plateforme coursier: ' data={produit?.plateformeCoursier} />
      <ProductData label='Situation actuelle: ' data={produit?.situationActuelle} />
      <ProductData label='Connaissance auto-entreprise: ' data={produit?.isConnaissanceAutoEntreprise} />
      <ProductData label='Accompagnement création micro-entreprise: ' data={produit?.isAccompagnementCreationMicroEntreprise} />
      <ProductData label='Genre: ' data={produit?.genre} />
      <ProductData label='Nom: ' data={produit?.nom} />
      <ProductData label='Prénom: ' data={produit?.prenom} />
      <ProductData label='Adresse domicile: ' data={produit?.adresseDomicile} />
      <ProductData label='Code postal: ' data={produit?.codePostal} />
      <ProductData label='Ville: ' data={produit?.ville} />
      <ProductData label='Adresse e-mail: ' data={produit?.adresseEmail} />
      <ProductData label='Numero de téléphone: ' data={produit?.numeroTelephone} />
      <ProductData label='Recherche: ' data={produit?.recherche} />
      <br />  <hr /> <br />


      <ProductData label='date de création: ' data={produit?.created_at} />
      <ProductData label='date de dérnère modification:' data={produit?.updated_at} />
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />

    </div>
  );
}

export function CarteGrise({

  produit,

  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Nom: ' data={produit?.nom} />
      <ProductData label='Prénom: ' data={produit?.prenom} />
      <ProductData label='Date de naissance: ' data={produit?.dateNaissance} />
      <ProductData label='Nationalité: ' data={produit?.nationalite} />
      <ProductData label='Cni: ' data={produit?.cni} />
      <ProductData label='Adresse: ' data={produit?.adresse} />
      <ProductData label='Téléphone: ' data={produit?.tel} />
      <ProductData label='Email: ' data={produit?.email} />
      <ProductData label='Marque de véhicule: ' data={produit?.marqueVehicule} />
      <ProductData label='Modele de véhicule: ' data={produit?.modeleVehicule} />
      <ProductData label='Numero de chasis: ' data={produit?.numeroChasis} />
      <ProductData label='Type de véhicule: ' data={produit?.typeVehicule} />
      <ProductData label='Date de mise en circulation: ' data={produit?.dateMiseCirculation} />
      <ProductData label='Puissance fiscale: ' data={produit?.puissanceFiscale} />
      <ProductData label={'Numero d\'immatriculation: '} data={produit?.numeroImmatriculation} />
      <br />  <hr /> <br />


      <ProductData label='date de création: ' data={produit?.created_at} />
      <ProductData label='date de dérnère modification:' data={produit?.updated_at} />
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />

    </div>
  );
}

export function Cession({

  produit,

  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination} />
      <ProductData label='Sigle: ' data={produit?.sigle} />
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique} />
      <ProductData label='Registre de commerce: ' data={produit?.rc} />
      <ProductData label='Vendeur: ' data={produit?.vendeur} />
      <ProductData label='Acheteur: ' data={produit?.acheteur} />
      <ProductData label='Date de cession: ' data={produit?.dateCession} />
      <ProductData label='Montant de cession: ' data={produit?.montantCession} />
      <ProductData label={'Déscription de l\'activité transférée: '} data={produit?.descriptionActiviteTransferee} />
      <br />  <hr /> <br />


      <ProductData label='date de création: ' data={produit?.created_at} />
      <ProductData label='date de dérnère modification:' data={produit?.updated_at} />
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />

    </div>
  );
}

export function Dissolution({

  produit,

  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination} />
      <ProductData label='Sigle: ' data={produit?.sigle} />
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique} />
      <ProductData label='Registre de commerce: ' data={produit?.rc} />
      <ProductData label='Liquidateur: ' data={produit?.liquidateur} />
      <ProductData label='Associés: ' data={produit?.associes} />
      <br />  <hr /> <br />


      <ProductData label='date de création: ' data={produit?.created_at} />
      <ProductData label='date de dérnère modification:' data={produit?.updated_at} />
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />

    </div>
  );
}

export function ChangementStatut({

  produit,

  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination} />
      <ProductData label='Sigle: ' data={produit?.sigle} />
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique} />
      <ProductData label='Registre de commerce: ' data={produit?.rc} />
      <ProductData label='Demandeur: ' data={produit?.demandeur} />
      <br />  <hr /> <br />


      <ProductData label='date de création: ' data={produit?.created_at} />
      <ProductData label='date de dérnère modification:' data={produit?.updated_at} />
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />

    </div>
  );
}
