import React, { useEffect, useState } from 'react';
import { Button, createTheme, Grid, MenuItem, TextField, ThemeProvider, Tooltip, IconButton, Drawer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCategoryDrawer from './addCategoryDrawer';

import CloseIcon from '@mui/icons-material/Close';
import app_logo from '../../assets/images/LOGO-LS@2x.png';

export default function UpdatePackDrawer({
  onClose,
  open,
  onClickUpdatePack,
  pack,
  categories = [],
  errors = {},
}) {
  const [type, setType] = useState(pack?.type || '');
  const [category_id, setCategory_id] = useState(pack?.category_id || '');
  const [offer, setOffer] = useState(pack?.offer || '');
  const [price, setPrice] = useState(pack?.price || '');
  const [sub_price, setSub_price] = useState(pack?.sub_price || '');
  const [items, setItems] = useState(pack?.items || []);
  const [openAddCategoryDrawer, setOpenAddCategoryDrawer] = useState(false);

  const handleAddCategory = (newCategory) => {
    setCategory_id(newCategory.id);
    categories.push(newCategory);
  };

  const handleItemsChange = (e) => {
    setItems(e.target.value.split(',').map(item => item.trim()));
  };

  const handleOpenAddCategoryDrawer = () => setOpenAddCategoryDrawer(true);
  const handleCloseAddCategoryDrawer = () => setOpenAddCategoryDrawer(false);

  useEffect(() => {
    setType(pack.type);
    setCategory_id(pack.category_id);
    setOffer(pack.offer);
    setPrice(pack.price);
    setSub_price(pack.sub_price);
    setItems(pack.items || []);
  }, [open]);

  {
    console.log('pack!!! ', pack);
  }
  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': { width: '70wv', maxWidth: '90wv' },
        }}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
            <div className='flex w-full justify-between items-center'>
              <img src={app_logo} alt='logo' className='w-[300px]' />
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </div>
          </div>
          <div style={{ padding: '20px', width: '50vw' }}>

            <h2 className='font-poppins font-medium text-2xl mb-5'>Modifier pack:</h2>

            <Grid container spacing={2} >


              {/* Type */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="type"
                  label="Type"
                  variant="outlined"
                  margin="dense"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  error={Boolean(errors?.type)}
                  helperText={errors?.type || ''}
                  fullWidth
                  placeholder="Ex: Premium"
                  required
                />
              </Grid>

              {/* Category */}
              <Grid item xs={12} sm={6} container alignItems="center" spacing={0}>
                <Grid item sm={10}>
                  <TextField
                    id="category"
                    select
                    label="Catégorie"
                    variant="outlined"
                    margin="dense"
                    value={category_id}
                    onChange={(e) => setCategory_id(e.target.value)}
                    error={Boolean(errors?.category)}
                    helperText={errors?.category || ''}
                    fullWidth
                    required
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={2}>
                  <Tooltip title="Ajouter une catégorie">
                    <IconButton
                      style={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        padding: 10,
                        borderRadius: 4,
                        height: 55,
                        top: 1,
                        left: 2,
                        right: 0,
                        width: 50,
                      }}
                      onClick={handleOpenAddCategoryDrawer}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              {/* Other Fields */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="offer"
                  label="Offre"
                  variant="outlined"
                  margin="dense"
                  value={offer}
                  onChange={(e) => setOffer(e.target.value)}
                  error={Boolean(errors?.offer)}
                  helperText={errors?.offer || ''}
                  fullWidth
                  placeholder="Ex: 6 Mois"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="price"
                  label="Prix"
                  type="number"
                  inputProps={{ min: 0 }}
                  variant="outlined"
                  margin="dense"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  error={Boolean(errors?.price)}
                  helperText={errors?.price || ''}
                  fullWidth
                  required
                  placeholder="1200"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="sub_price"
                  label="Prix de base"
                  type="number"
                  inputProps={{ min: 0 }}
                  variant="outlined"
                  margin="dense"
                  value={sub_price}
                  onChange={(e) => setSub_price(e.target.value)}
                  error={Boolean(errors?.sub_price)}
                  helperText={errors?.sub_price || ''}
                  fullWidth
                  placeholder="1500"
                />

              </Grid>

            </Grid>
            <Grid sx={{ marginTop: '16px' }} container>
              <TextField
                id="items"
                label="Éléments (séparés par des virgules)"
                variant="outlined"
                margin="dense"
                value={Array.isArray(items) ? items.join(', ') : ''}
                onChange={handleItemsChange}
                error={Boolean(errors?.items)}
                helperText={errors?.items || ''}
                fullWidth
                multiline
                rows={4}
                placeholder="Service A, Service B"
              />
            </Grid>

            {/* Action Buttons */}
            <Grid container spacing={2} sx={{ marginTop: '16px' }} >
              <Grid item xs={6}>
                <Button variant="contained" size="large" onClick={onClose} fullWidth>
                  Annuler
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => onClickUpdatePack({ id: pack.id, type, category_id, offer, price, sub_price, items })}
                  fullWidth
                >
                  Modifier
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>

      <AddCategoryDrawer
        open={openAddCategoryDrawer}
        onClose={handleCloseAddCategoryDrawer}
        onCategoryAdded={handleAddCategory}
      />
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {

    MuiButton: {
      styleOverrides: { root: { marginTop: '10px' } },
    },
  },
});
