import React, { useEffect, useState } from 'react';
import { IconButton, Button, createTheme, Grid, TextField, ThemeProvider, Drawer } from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';
import app_logo from '../../assets/images/LOGO-LS@2x.png';

export default function UpdateManagerDrawer({
  onClose,
  open,
  onClickUpdateManager,
  manager,
  errors,
}) {
  const [nom, setNom] = useState(manager.nom);
  const [prenom, setPrenom] = useState(manager.prenom);
  const [email, setEmail] = useState(manager.email);
  const [phone, setPhone] = useState(manager.phone);
  const [birthDate, setBirthDate] = useState(manager.birthDate);

  useEffect(() => {
    setNom(manager.nom);
    setPrenom(manager.prenom);
    setEmail(manager.email);
    setPhone(manager.phone);
    setBirthDate(manager.birthDate);
  }, [manager]);

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': { width: '70wv', maxWidth: '90wv' },
        }}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
            <div className='flex w-full justify-between items-center'>
              <img src={app_logo} alt='logo' className='w-[300px]' />
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </div>
          </div>
          <div style={{ padding: '90px  20px 20px 20px', width: '50vw' }}>

            <h2 className='font-poppins font-medium text-2xl mb-5'>Modifier un géstionnaire:</h2>

            <Grid container spacing={2}>
              {/* nom */}
              <Grid item xs={6}>
                <TextField
                  id="nom"
                  label="Nom"
                  variant="outlined"
                  margin="dense"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  error={errors && errors.nom}
                  helperText={errors && errors.nom ? errors.nom : ''}
                  fullWidth
                />
              </Grid>
              {/* prénom */}
              <Grid item xs={6}>
                <TextField
                  id="prenom"
                  label="Prénom"
                  variant="outlined"
                  margin="dense"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  error={errors && errors.prenom}
                  helperText={errors && errors.prenom ? errors.prenom : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* email */}
              <Grid item xs={6}>
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                  margin="dense"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={errors && errors.email}
                  helperText={errors && errors.email ? errors.email : ''}
                  fullWidth
                />
              </Grid>
              {/* phone */}
              <Grid item xs={6}>
                <TextField
                  id="phone"
                  type="tel"
                  label="Téléphone"
                  variant="outlined"
                  margin="dense"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={errors && errors.phone}
                  helperText={errors && errors.phone ? errors.phone : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* birthDate */}
            <Grid container>
              <TextField
                id="birthDate"
                type="date"
                label="Date de naissance"
                variant="outlined"
                margin="dense"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                error={errors && errors.birthDate}
                helperText={errors && errors.birthDate ? errors.birthDate : ''}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={onClose}
                  fullWidth
                >
                  Annuler
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    onClickUpdateManager({
                      id: manager.id,
                      nom,
                      prenom,
                      email,
                      phone,
                      birthDate,
                    })
                  }
                  fullWidth
                >
                  Modifier
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {

    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        },
      },
    },
  },
});
