import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';

function PackCategoryTable({
  header,
  data,
  onClickDeleteCategory,
  onClickUpdateCategory
}) {
  return (
    <table className='table-auto w-full'>
      <thead>
        <tr className='border-solid border-b border-[#EBEBEB]'>
          {header.map((item, index) => (
            <th key={index}
              className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 `}

            >{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((category) => (
          <tr key={category.id}>
            <td className='font-poppins font-normal text-sm/[16px] text-center'>{category.id}</td>
            <td className='font-poppins font-normal text-sm/[16px] text-center'>{category.name}</td>
            <td className='font-poppins font-normal text-sm/[16px] text-center'>{category.title}</td>
            <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(category.created_at), 'dd/MM/yyyy')}</td>
            <td className='font-poppins font-normal text-sm/[16px] text-center'>
              <Tooltip title="Edit">
                <IconButton onClick={() => onClickUpdateCategory(category.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => onClickDeleteCategory(category.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default PackCategoryTable;
