import React from "react";

import getEnvironement from "../../../environnement";
const GenerateAttestationPDF = ({ productData }) => {


  const formattedDate = productData?.created_at
    ? new Date(productData.created_at).toISOString().split('T')[0]
    : 'Date non spécifiée';

  return (


    <div style={{ fontFamily: "Arial, sans-serif", paddingRight : '20mm', paddingLeft:'20mm',  paddingTop:'5mm', paddingBottom:'10mm', fontSize: "18px", lineHeight: "1.5" }}>
      <div style={{ width: "100%", background: "white",  }}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img style={{ width: '40%', display: 'inline-block' }} src={`${getEnvironement().BACKEND_URL}/storage/app/public/${productData?.siege_social?.logo}`} alt="" />

        </div>
        <h2 style={{ textAlign: "center", fontWeight: 'bold' , textTransform: "uppercase", marginBottom: "20px" }}>
          Attestation de domiciliation
        </h2>

        <div style={{ textAlign: "left" }}>
          Nous soussignés <b>{productData?.siege_social?.name} AU</b>, {productData?.siege_social?.address}
        </div>
        <div style={{ marginTop: '30px', width: '100%' }}>
          <p> <b>PATENTE :</b> {productData?.siege_social?.patente}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>IF :</b> {productData?.siege_social?.ifCode}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>RC :</b> {productData?.siege_social?.rc}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>CNSS :</b> {productData?.siege_social?.cnss}</p>
        </div>

        <p style={{ marginTop: '30px', textAlign: "justify", marginBottom:'10px' }}>
          Déclare par la présente suivant l'instruction No. 1923, donner domiciliation à <b>{productData?.denominationValide}</b> à l'adresse suivante : {productData?.siege_social?.address}.
        </p>

        <p style={{ textAlign: "justify", marginBottom:'10px' }}>Nous déclarons en outre avoir pris connaissance qu’en application des dispositions de l’article 93 du code de recouvrement des créances publiques, les rôles des impôts, états de produits et autres titres de perception régulièrement émis ont exécutoire contre les redevables qui y sont inscrits, toutes autres personnes auprès desquelles les redevables ont élu domicile fiscal, avec leur accord.</p>

        <p style={{textAlign: "justify", marginBottom:'30px' }}>Les personnes auprès desquelles les redevables ont élu domicile fiscal avec leur accord peuvent, de ce fait, faire l’objet d’action en recouvrement au même titre que les redevables à raison des créances dues au titre de l’activité concernée par la durée de la domiciliation.</p>

        <p style={{ marginTop: '30px', textAlign: "justify", marginBottom:'10px' }}> Cette attestation est délivrée pour servir et valoir ce que de droit.</p>

        <div style={{ textAlign: "right", marginTop: '50px' }}>
          <div style={{ display: "inline-block", textAlign: "center" }}>
            <p>Fait à {productData?.siege_social?.city} le : <b>{formattedDate}</b></p>
            <p><b>Mr. {productData?.siege_social?.manager}</b></p>
            <p>Gérant</p>
          </div>
        </div>
        {/*FOOTER */}
        <div style={{ width: '100%', bottom: '0', marginTop: '65px', fontSize:'14px', textAlign: "center"}}>
          <b>Adresse :</b> {productData?.siege_social?.address}
          <br />
          <b>Tél :</b> {productData?.siege_social?.phone} – <b>Fax :</b> {productData?.siege_social?.fax} – <b>E-mail :</b> {productData?.siege_social?.email}
          <br />
          <b>RC :</b> {productData?.siege_social?.rc} - <b>PATENTE :</b> {productData?.siege_social?.patente} - <b>IF :</b> {productData?.siege_social?.ifCode} - <b>CNSS :</b> {productData?.siege_social?.cnss} - <b>ICE :</b> {productData?.siege_social?.ice}
        </div>
      </div>
    </div >


  );
};

export default GenerateAttestationPDF;
